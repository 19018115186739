
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/entry/Email"
import Skeleton from "primevue/skeleton"
import Button from "primevue/button"
import dayjs from "@/util/dayjs"
import DateTimeUtil from "@/util/DateTimeUtil"
import CalendarEvent from "../../model/entry/Event"
import User from "@/model/User"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import {userServiceApi} from "@/api/UserServiceApi"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Chip from 'primevue/chip'
import {mailServiceApi} from "@/api/MailServiceApi"
import Dialog from "primevue/dialog"
import Listbox from "primevue/listbox"
import Calendar from "@/model/directory/Calendar"
import SWR from "@/api/SWR"
import {calendarServiceApi} from "@/api/CalendarServiceApi"
import ProgressBar from "primevue/progressbar"
import DetailButtons from "@/components/email/DetailButtons.vue"
import {rpcClient} from '@/api/WebsocketClient'
import useToast from "@/util/toasts"
import {reactive, ref} from "@vue/reactivity"
import Attendee from "@/model/common/caldav/Attendee"
import SettingsUtil from "@/util/SettingsUtil"
import LoadingButton from "@/components/common/LoadingButton.vue"
import InboxItem from "@/components/calendar/InboxItem.vue"
import SchedulingObject from "@/model/SchedulingObject"
import Event from "../../model/entry/Event"
import {eventServiceApi} from "@/api/EventServiceApi"
import {Router, useRouter} from "vue-router"
import {calendarInboxServiceApi} from "@/api/CalendarInboxServiceApi"
import {emailStore} from "@/store/EmailStore"
import RpcError from "@/api/RpcError"
import TaskCreator from "@/components/common/TaskCreator.vue"
import {toEvent, toTask} from "@/router"
import {entryLinkServiceApi} from "@/api/EntryLinkServiceApi"
import EntryLink from "@/model/EntryLink"
import {Watch} from "vue-property-decorator"

@Options({
  //@ts-ignore
  props: {
    folderId: String,
    email: [Email, Object],
    mode: {type: String, default: 'rich'},
    addButtons: {type: Boolean, default: false}
  },
  components: {
    TaskCreator,
    LoadingButton, Skeleton, Button, Chip, Dialog, Listbox, ProgressBar, DetailButtons, InboxItem
  },
  emits: [
    'email:forward',
    'email:print',
    'email:deleted',
  ]
})
export default class DetailBody extends Vue {

  rpcClient = rpcClient
  folderId!: string
  email!: Email
  mode!: string

  calendarsAreLoading: boolean = false
  selectedCalendarForInvite: string | null = null
  handlingIMIPLoading: boolean = false
  showLoadImagesDialog: boolean = false
  showTaskCreatorDialog: boolean = false
  allowImagesInternal: boolean = false

  //@ts-ignore
  emailIframe: HTMLIFrameElement = ref(null)

  i18n: Language = useGettext()
  toast = useToast()
  router: Router = useRouter()

  linkError: boolean = false
  loadingLinks: boolean = true
  linkedTaskId: string = ""
  linkedTaskParentId!: string

  handleForward(event: {id: string, replyAll: boolean}) {
    this.$emit('email:forward', event)
  }

  handlePrint(event: {id: string}) {
    this.$emit('email:print', event)
  }

  handleDeleted(event: {id: string}) {
    this.$emit('email:deleted', event)

  }

  attendeesTypeOptions: any[] = [
    {
      id: 'OPTIONAL',
      name: this.i18n.$gettext('Optional')
    },
    {
      id: 'REQUIRED',
      name: this.i18n.$gettext('Required')
    },
    {
      id: 'FYI',
      name: this.i18n.$gettext('Fyi')
    }
  ]

  get iframeId(): string {
    if (this.email.originalId) {
      return "iframeId-" + this.email.originalId.replaceAll('=', '-')
    }
    return 'iframeId'
  }

  get allowImages() {
    if (this.allowImagesInternal) {
      return true
    } else {
      const sender: string = (this.email?.fromEmail && this.email?.fromEmail?.length > 0) ? this.email?.fromEmail[0] : '*'
      return sender.endsWith('@' + window.location.host) || SettingsUtil.getLoadImagesBySender(sender)
    }
  }

  showLoadImagesDialogIfEnabled() {
    this.allowImagesInternal = true
    if (SettingsUtil.showLoadImagesDialog()) {
      this.showLoadImagesDialog = true
    }
  }

  disableLoadImagesDialog(): Promise<any> {
    return SettingsUtil.disableLoadImagesDialog().finally(() => {
      this.showLoadImagesDialog = false
    })
  }

  loadImagesForSender(): Promise<any> {
    if (this.email?.fromEmail && this.email?.fromEmail?.length > 0) {
      const sender: string = this.email.fromEmail[0]
      return SettingsUtil.addSenderToLoadImagesBySender(sender).finally(() => {
        this.showLoadImagesDialog = false
      })
    } else {
      this.showLoadImagesDialog = false
      return Promise.reject('No sender in email!')
    }
  }

  alwaysLoadImages(): Promise<any> {
    return SettingsUtil.addSenderToLoadImagesBySender('*').finally(() => {
      this.showLoadImagesDialog = false
    })
  }

  downloadAttachment(attachment: string) {
    if (this.email && this.email.originalId) {
      window.open(
        '/groupware-api/v1/emails/' +
          encodeURIComponent(this.email.originalId) +
          '/attachments/' +
          encodeURIComponent(this.b64EncodeUnicode(attachment)),
        '_blank'
      )
    }
  }

  // https://stackoverflow.com/a/30106551
  b64EncodeUnicode(str: string) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode(parseInt(p1, 16))
    }))
  }

  get separateRecipients(): boolean {
    /*let setting: Setting | undefined = settingsServiceApi.settings.find(setting => setting.key == 'DETAIL_ADDRESS_DISPLAY')
    if (setting) {
      return setting.value === 'separate'
    } else {
      return false
    }*/
    return true
  }

  get allRecipients() {
    return this.email ? (this.email.to || []).concat(this.email.cc || []).concat(this.email.bcc || []) : []
  }

  get receivedDate(): string {
    return this.email.receivedDate ? dayjs(this.email.receivedDate).format('LLL') : ''
  }

  get users(): User[] {
    return userServiceApi.getUsers(10000).data || []
  }

  getFormattedEventTime(event: CalendarEvent | null): string {
    if(!event) return ""
    if (event?.start) {
      const start = new Date(event.start)
      let end: Date | null = null
      if (event.duration) {
        end = new Date(start.getTime() + DateTimeUtil.getMillisFromDuration(event.duration))
      } else if (event.end) {
        end = new Date(event.end)
      }
      const startString = event.allDay ? dayjs(start).format('dddd, L') : dayjs(start).format('dddd, LLL')
      const allDayEnd = end ? dayjs(end).add(-1, 'day').toDate(): null
      const sameDay: boolean = DateTimeUtil.isSameDay(start, event.allDay ? allDayEnd : end)
      if (!end || (sameDay && event.allDay)) {
        return startString
      } else if (end && sameDay) {
        return startString + ' - ' + dayjs(end).format('LT')
      } else if (end && event.allDay) {
        return startString + ' - ' + dayjs(end).add(-1, 'day').format('dddd, L')
      } else {
        return startString + ' - ' + dayjs(end).format('dddd, LLL')
      }
    } else {
      return ''
    }
  }

  adjustIframeHeight(): void {
    if (this.emailIframe?.contentWindow?.document.body.scrollHeight) {
      const margin = 50
      let newHeight: number = this.emailIframe?.contentWindow?.document.body.scrollHeight + margin
      this.emailIframe.style.height = newHeight + 'px'
    }
  }

  attendeeImage(email: string): string | null {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      const image: CachedImage = imageLoadingService.getCachedImage(`/groupware-api/v1/users/${user.userName}/picture`)
      return image.error ? null : image.cached
    } else {
      return null
    }
  }

  getReplyMessage(request: CalendarEvent): string {
    if (request && request.comments && request.comments[0]) {
      return request.comments[0]
    }
    return ""
  }

  attendeeStatusColor(status: string): string {
    let classString: string = "mr-2 "
    if (status === this.Participation_ACCEPTED) classString += " text-success"
    else if (status === this.Participation_TENTATIVE) classString += " text-info"
    else classString += " text-danger"
    return classString
  }

  attendeeName(email: string): string {
    const user: User | undefined = this.users.find(u => u.email === email)
    if (user) {
      return user.displayName || ((user.givenname || '') + (user.surname || '')) || email
    } else {
      return email
    }
  }

  getAttendees(request: CalendarEvent | null): Attendee[] {
    if (!request) return []
    if (request.requestFor && request.requestFor.attendees && request.requestFor.attendees.length > 0) {
      return request.requestFor.attendees
    }
    return request.attendees || []
  }

  getAttendeeStatusTooltip(attendee: Attendee | null): string {
    if (!attendee) return ''
    let tooltip: string = this.attendeesTypeOptions.find(o => o.id === attendee.participationLevel)?.name || ''
    if (tooltip)
      tooltip += ': '
    tooltip += this.getAttendeeStatus(attendee.status)

    return tooltip
  }

  getAttendeeStatus(status: string | null): string {
    let participation: any | null = this.participationOptions.find(o => o.id === status)
    if (!participation) {
      participation = this.participationOptions.find(o => o.id === this.Participation_NEEDS_ACTION)
    }
    return participation.name
  }

  Participation_NEEDS_ACTION: string = "NEEDS_ACTION"
  Participation_ACCEPTED: string = "ACCEPTED"
  Participation_DECLINED: string = "DECLINED"
  Participation_TENTATIVE: string = "TENTATIVE"
  Participation_DELEGATED: string = "DELEGATED"
  Participation_COMPLETED: string = "COMPLETED"
  Participation_IN_PROCESS: string = "IN_PROCESS"
  Participation_CONFIRMED: string = "CONFIRMED"
  Participation_SENT: string = "SENT"

  participationOptions: any[] = [
    {
      id: this.Participation_NEEDS_ACTION,
      name: this.i18n.$gettext('Needs Action')
    },
    {
      id: this.Participation_ACCEPTED,
      name: this.i18n.$gettext('Accepted')
    },
    {
      id: this.Participation_DECLINED,
      name: this.i18n.$gettext('Declined')
    },
    {
      id: this.Participation_TENTATIVE,
      name: this.i18n.$gettext('Tentative')
    },
    {
      id: this.Participation_DELEGATED,
      name: this.i18n.$gettext('Delegated')
    }
  ]

  get calendars(): Calendar[] {
    const swr: SWR<Calendar[], string[]> = calendarServiceApi.getCalendars()
    this.calendarsAreLoading = Boolean(swr.call?.loading && swr.call?.promise)
    if (swr.call?.promise) {
      swr.call.promise.finally(() => {
        this.calendarsAreLoading = false
      })
    }
    return swr.data || []
  }

  get schedulingObject(): { eventId: string, event: CalendarEvent | null, messages: SchedulingObject[] } | null {
    const events: CalendarEvent[] | undefined | null = this.email?.schedulingObject?.eventsFromRequest
    if (events && events.length > 0) {
      const event: CalendarEvent = events.find(e => !!e.requestFor) || events[0]
      const eventId: string | null = event.requestFor?.originalId || event.originalId
      if (eventId && this.email.schedulingObject) {
        //Refresh the event, in case this getter was called after the user answered a scheduling request
        const swr: SWR<Event | null, string> = eventServiceApi.getEvent(eventId, true)
        event.requestFor = swr.data || event.requestFor
        const schedulingObject: { eventId: string, event: CalendarEvent, messages: SchedulingObject[] } = reactive({ eventId: eventId, event: event, messages: [] })
        schedulingObject.messages.push(this.email.schedulingObject)
        swr.call?.promise?.finally(() => {
          event.requestFor = swr.data || event.requestFor
        })
        return schedulingObject
      } else if (event?.uid && this.email.schedulingObject) {
        const schedulingObject: { eventId: string, event: CalendarEvent, messages: SchedulingObject[] } = reactive({ eventId: event.uid, event: event, messages: [] })
        schedulingObject.messages.push(this.email.schedulingObject)
        return schedulingObject
      } else {
        return null
      }
    } else {
      return null
    }
  }

  setParticipationState(so: SchedulingObject, event: CalendarEvent, status: string) {
    if (so.eventsFromRequest && so.eventsFromRequest.length && status && this.email?.originalId) {
      this.handlingIMIPLoading = true
      so.eventsFromRequest[0].requestFor = event
      return mailServiceApi._handleIMIPRequest(this.email.originalId, so.eventsFromRequest[0], status).then(() => {
        if (status === this.Participation_ACCEPTED) this.toast.success(this.i18n.$gettext('Your new attendee status is accepted'))
        if (status === this.Participation_DECLINED) this.toast.success(this.i18n.$gettext('Your new attendee status is declined'))
        if (status === this.Participation_TENTATIVE) this.toast.success(this.i18n.$gettext('Your new attendee status is tentative'))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to update participation"))
      }).finally(() => {
        this.handlingIMIPLoading = false
      })
    } else{
      this.toast.error(this.i18n.$gettext("Invalid scheduling request"))
    }
  }

  setAttendeeParticipationState(so: SchedulingObject, event: CalendarEvent, status: string) {
    if (so.eventsFromRequest && so.eventsFromRequest.length && event && status && this.email?.originalId) {
      this.handlingIMIPLoading = true
      so.eventsFromRequest[0].requestFor = event
      return mailServiceApi._handleIMIPReply(this.email.originalId, so.eventsFromRequest[0], status).then(() => {
        if (status === this.Participation_ACCEPTED) this.toast.success(this.i18n.$gettext('You accepted the new attendee'))
        if (status === this.Participation_DECLINED) this.toast.success(this.i18n.$gettext('You removed the attendee'))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to update participation"))
      }).finally(() => {
        this.handlingIMIPLoading = false
      })
    } else{
      this.toast.error(this.i18n.$gettext("Invalid scheduling request"))
    }
  }

  handleCounterEvent(so: SchedulingObject, event: CalendarEvent, accepted: boolean) {
    if (so.eventsFromRequest && so.eventsFromRequest.length && event && status && this.email?.originalId) {
      this.handlingIMIPLoading = true
      so.eventsFromRequest[0].requestFor = event
      return mailServiceApi._handleIMIPCounter(this.email.originalId, so.eventsFromRequest[0], accepted).then(() => {
        if (accepted) this.toast.success(this.i18n.$gettext('You accepted the counter event'))
        else this.toast.success(this.i18n.$gettext('You declined the counter event'))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to reply to counter event"))
      }).finally(() => {
        this.handlingIMIPLoading = false
      })
    } else{
      this.toast.error(this.i18n.$gettext("Invalid scheduling request"))
    }
  }

  handleCancel(so: SchedulingObject, event: CalendarEvent) {
    if (so && so.id && event) {
      this.handlingIMIPLoading = true
      return calendarInboxServiceApi._handleCancel(so, event).then(() => {
        this.toast.success(this.i18n.$gettext('Cancel accepted'))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Cancel failed"))
      }).finally(() => {
        this.handlingIMIPLoading = false
      })
    } else if (event?.requestFor?.originalId) {
      return eventServiceApi._deleteEvent(event.requestFor.originalId).then(() => {
        void this.updateSchedulingObject()
        this.toast.success(this.i18n.$gettext('Event deleted'))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Could not delete event"))
      })
    } else {
      this.toast.error(this.i18n.$gettext("Invalid scheduling request"))
    }
  }

  resendEvent(event: CalendarEvent) {
    if(event && this.email?.originalId) {
      this.handlingIMIPLoading = true
      return mailServiceApi._handleIMIPRefresh(this.email.originalId, event).then(() => {
        this.toast.success(this.i18n.$gettext('Event resent'))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to resent event"))
      }).finally(() => {
        this.handlingIMIPLoading = false
      })
    } else{
      this.toast.error(this.i18n.$gettext("Invalid scheduling request"))
    }
  }

  updateSchedulingObject() {
    if (this.email.originalId) {
      return mailServiceApi._getFullEmail(this.email.originalId).then(id => {
        const email: Email | undefined = emailStore.state.emails.get(id)
        if (email) {
          this.email.schedulingObject = email.schedulingObject
        }
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Could not update event"))
      })
    }
  }

  checkForExistingLink(): void {
    if (this.email && this.email.originalId) {
      this.loadingLinks = true
      entryLinkServiceApi._getLinksByTypeAndBackendIdAndOtherType(
        "EMAIL", this.email.originalId, "TASK"
      ).then((entryLinks: EntryLink[]) => {
        if (entryLinks && entryLinks.length > 0) {
          let linkedTaskId: string | null
          let linkedTaskParentId: string | null
          for (let idx=0; idx<entryLinks.length; idx++)  {
            if (entryLinks[idx].meta) {
              if (entryLinks[idx].leftBackendId == this.email?.originalId) {
                linkedTaskId = entryLinks[idx].rightBackendId
                linkedTaskParentId = entryLinks[idx].meta?.rightOriginalParentId || null
              } else {
                linkedTaskId = entryLinks[idx].leftBackendId
                linkedTaskParentId = entryLinks[idx].meta?.leftOriginalParentId || null
              }
              if (linkedTaskId && linkedTaskParentId) {
                this.linkedTaskParentId = linkedTaskParentId
                this.linkedTaskId = linkedTaskId
                return
              }
            }
          }
        }
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to get linked task"))
        this.linkError = true
      }).finally(() => {
        this.loadingLinks = false
      })
    }
  }

  get hasLink(): boolean {
    if (this.linkedTaskId && this.linkedTaskParentId)
      return true
    return false
  }

  hideTaskCreator() {
    this.showTaskCreatorDialog = false
    this.checkForExistingLink()
  }

  goToEvent(event: CalendarEvent) {
    if (event && event.originalParentId && event.originalId) {
      toEvent(event.originalParentId, event.originalId)
    }
  }

  goToTask() {
    if (this.linkedTaskParentId && this.linkedTaskId) {
      toTask(this.linkedTaskParentId, this.linkedTaskId)
    }
  }

  @Watch("email")
  watchEditor(email: Email | null, oldValue: Email | null) {
    this.checkForExistingLink()
  }

  mounted() {
    this.watchEditor(this.email, null)
  }
}
