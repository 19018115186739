<template>
  <div class="d-flex flex-column vh-100">
    <div class="bg-danger z-1 w-100 collapse" :class="{ show: rpcClient.state.failed }">
      <div class="container text-center p-2">
        <p class="font-weight-bold text-white mb-0">
          <translate>No connection to server.</translate>
        </p>
      </div>
    </div>
    <div class="bg-success z-1 w-100 collapse" :class="{ show: showMigrationBanner }" style="position: relative">
      <div class="mx-6 p-2 text-center">
        <p class="font-weight-bold text-white mb-0">
          <translate>Data import is running...</translate>&nbsp;&nbsp;
        </p>
        <p class=" mb-1 text-white">
          <translate>While the data import ist running, the user interface and all of the software might run slower than usual.</translate>
        </p>
      </div>
    </div>
    <div class="bg-success z-1 w-100 collapse" :class="{ show: showNoBackupsWarning }" style="position: relative">
      <div class="mx-6 p-2 text-center">
        <p class=" mb-1 text-white">
          <span class="font-weight-bold"><translate>No backup jobs configured.</translate>&nbsp;</span>
          <translate>Uniki offers several secure options to backup your data locally and remotely.</translate>
        </p>
        <Button
          :label="i18n.$gettext('Configure backup')"
          icon="cil-shield-check"
          class="p-button mt-2 mr-3"
          @click="showNoBackupsWarning = false; $router.push('/settings/backup')"
        />
        <Button
          :label="i18n.$gettext('Hide message')"
          icon="cil-x"
          class="p-button p-button-secondary mt-2 mr-3"
          @click="hideBackupJobsWarning"
        />
      </div>
    </div>
    <Toast />
    <MenuBar v-if="rpcClient.fullyLoggedIn" class="d-flex flex-row card card-lite z-1 p-0 flex-shrink-0">
      <template #start>
        <div id="btn-projectswitch" class="flex-shrink-0 py-1" style="display: inline-flex">
          <div class="menu-toggle text-center logo-container pt-1 pl-2">
            <img src="/images/elly_logo.png" style="height: 40px">
          </div>
          <h3 class="mb-auto mt-auto pt-1 ml-2 text-primary">
            UNIKI
          </h3>
        </div>
      </template>
      <template #content>
        <div id="menubarcontent" class="d-flex w-100 pl-2 pr-2 justify-content-between align-items-center flex-wrap">
          <div id="topmenubar" class="d-flex flex-grow-1 flex-shrink-0">
            <Button
              v-if="shouldAskForNotificationPermission"
              :label="i18n.$gettext('Enable Notifications')"
              icon="cil-bell-ring"
              class="p-button p-button-inverse mt-2 mr-3"
              @click="askForNotification"
            />
          </div>
        </div>
      </template>
    </MenuBar>
    <Dialog v-model:visible="shouldShowModalForNotifications" :closable="false">
      <div class="p-3">
        <p><translate>Please confirm the browser interaction to enable native notifications</translate></p>
        <Button class="p-button p-button-raised" :label="i18n.$gettext('OK')" @click="showModalForNativeNotify = false" />
      </div>
    </Dialog>
    <div v-if="rpcClient.session.adminpanelLoggedIn || rpcClient.fullyLoggedIn" class="d-flex flex-row h-100 flex-shrink-1" style="min-height: 0">
      <SideBar
        v-if="rpcClient.fullyLoggedIn"
        class="collapsible-sidebar"
        :links="links"
        :integrate-adminpanel="integrateAdminpanel"
        @show-help-overlay="showHelpOverlay = true"
      />
      <div v-if="rpcClient.fullyLoggedIn" class="h-100 flex-grow-1" style="min-width: 1px">
        <router-view id="route-content" class="h-100" />
      </div>
      <HelpCenter v-if="showHelpOverlay" @close="showHelpOverlay = false" />
    </div>
    <Adminpanel v-else-if="integrateAdminpanel" />
    <LoginForm v-else />
    <DemoNaggingPopup ref="demoNaggingPopup" />
    <ConfirmDialog />
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import LoginForm from './components/LoginForm.vue'
import {rpcClient} from '@/api/WebsocketClient'
import SideBar from "@/components/SideBar.vue"
import MenuBar from "@/components/common/MenuBar.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import ConfirmDialog from "primevue/confirmdialog"
import Button from "primevue/button"
import Avatar from "@/components/common/Avatar.vue"
import InputText from "primevue/inputtext"
import AutoComplete from "primevue/autocomplete"
import Adminpanel from "@/components/Adminpanel.vue"
import AdminpanelHolder from "@/util/AdminpanelHolder"
import Toast from "primevue/toast"
import SearchBar from "@/components/common/SearchBar.vue"
import Channel from "@/model/directory/Channel"
import {projectServiceApi} from "@/api/ProjectServiceApi"
import {channelServiceApi} from "@/api/ChannelServiceApi"
import MailFolder from "@/model/directory/MailFolder"
import {mailFolderServiceApi} from "@/api/MailFolderServiceApi"
import desktopNotificationUtil from "@/util/DesktopNotificationUtil"
import Dialog from "primevue/dialog"
import {Watch} from "vue-property-decorator"
import HelpCenter from "@/components/helpcenter/HelpCenter.vue"
import Fieldset from "primevue/fieldset"
import migrationStatusService, {MigrationStatus} from "@/util/migrationStatus"
import DemoNaggingPopup from "@/components/DemoNaggingPopup.vue"
import {reactive, ref} from "@vue/reactivity"
import demoService from "@/util/demoService"
import SettingsUtil from "@/util/SettingsUtil"
import {Router, useRouter} from "vue-router"
import {calendarInboxServiceApi} from "@/api/CalendarInboxServiceApi"
import FocusListener from "@/util/focusUtil"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import SWR from "@/api/SWR"
import CalendarEvent from "@/model/entry/Event"
import SchedulingObject from "@/model/SchedulingObject"
import Event from "@/model/entry/Event"
import {eventServiceApi} from "@/api/EventServiceApi"
import {userServiceApi} from "@/api/UserServiceApi"
import {taskServiceApi} from "@/api/TaskServiceApi"
import 'flatpickr/dist/flatpickr.css'


@Options({
  components: {
    DemoNaggingPopup,
    //@ts-ignore
    SearchBar, Adminpanel, LoginForm, SideBar, MenuBar, ConfirmDialog, Button, Avatar, InputText, AutoComplete, Toast, Dialog, HelpCenter, Fieldset
  }
})
export default class App extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient
  channelApi = channelServiceApi
  showModalForNativeNotify: boolean = false
  showHelpOverlay: boolean = false
  showNoBackupsWarning: boolean = false

  backupJobsWatcher = (state: any) => {
    if (SettingsUtil.getHideNoBackupJobsWarning()) {
      const watcherIndex = AdminpanelHolder.watchers.indexOf(this.backupJobsWatcher)
      if (watcherIndex >= 0) {
        AdminpanelHolder.watchers.splice(watcherIndex)
      }
      this.showNoBackupsWarning = false
    } else if (Array.isArray(state.backupJob.backupJobs) && state.backupJob.backupJobs.length === 0 &&
      this.isAdmin && AdminpanelHolder.instance?.store?.getters?.loggedInIsSigning) {
      const path = this.router?.currentRoute?.path || this.router?.currentRoute?.value?.path
      this.showNoBackupsWarning = !path?.endsWith('/settings/backup') && !SettingsUtil.getHideNoBackupJobsWarning()
    }
  }
  backupJobsPromise: Promise<any> | null = null
  //@ts-ignore
  demoNaggingPopup: DemoNaggingPopup = ref(null)

  focusListener: FocusListener = new FocusListener(() => {
    //Just trigger the refresh:
    channelServiceApi.getChannelsForTeam(this.currentProjectId, 20000)
    mailFolderServiceApi.getFolders(20000)
    calendarInboxServiceApi.readInbox(20000)
    //TODO update tasks count without triggering getTasks during tasks drag&drop action
  })

  get shouldShowModalForNotifications(){
    return desktopNotificationUtil.shouldAskForNotificationPermission && this.showModalForNativeNotify
  }

  @Watch('shouldAskForNotificationPermission')
  reactOnGivenNotificationPermission(){
    this.showModalForNativeNotify = false
  }

  get integrateAdminpanel() {
    return AdminpanelHolder.integrateAdminpanel
  }

  get migrationStatus(): MigrationStatus {
    return migrationStatusService.migrationStatus
  }

  get hasAnyMigrationStatus(): boolean {
    return migrationStatusService.hasAnyMigrationStatus
  }

  get hasActiveMigration(): boolean {
    return migrationStatusService.hasMigrationStatus
  }

  get showMigrationBanner(): boolean {
    return rpcClient.fullyLoggedIn && this.hasActiveMigration
  }

  get shouldAskForNotificationPermission() {
    return desktopNotificationUtil.shouldAskForNotificationPermission
  }

  askForNotification() {
    desktopNotificationUtil.askForNotification()
    this.showModalForNativeNotify = true
  }

  get links() {
    const links: any[] = [
      {
        href: '/',
        icon: 'cil-gauge cil-fw',
        text: this.i18n.$gettext('Dashboard')
      },
      {
        href: '/files',
        icon: 'cil-folder cil-fw',
        text: this.i18n.$gettext('Files')
      },
      {
        href: '/chat',
        icon: 'cil-chat-square cil-fw',
        text: this.i18n.$gettext('Chat'),
        notificationCount: this.unreadMessageCount
      },
      {
        href: '/mail',
        icon: 'cil-mail cil-fw',
        text: this.i18n.$gettext('Mail'),
        notificationCount: this.unreadEmailCount
      },
      {
        href: '/calendar',
        icon: 'cil-calendar cil-fw',
        text: this.i18n.$gettext('Calendar'),
        notificationCount: this.inboxCount
      },
      {
        href: '/tasks',
        icon: 'cil-task cil-fw',
        text: this.i18n.$gettext('Tasks'),
        notificationCount: taskServiceApi.overDueCount
      },
      {
        href: '/contacts',
        icon: 'cil-group cil-fw',
        text: this.i18n.$gettext('Contacts')
      }

    ]
    if (this.integrateAdminpanel) {
      links.push({
        href: '/apps',
        icon: 'cil-applications cil-fw',
        text: this.i18n.$gettext('Applications')
      })
      if (this.isAdmin) {
        links.push({
          href: '/store',
          icon: 'cil-cart cil-fwt',
          text: this.i18n.$gettext('App Store')
        })
      }
    }
    return links
  }

  get projects() {
    return projectServiceApi.getProjects(10000).data || []
  }

  get currentProjectId(): string | null {
    if (this.projects && this.projects.length > 0) {
      return this.projects[0].id
    } else {
      return null
    }
  }

  get channels(): Channel[] {
    const channels = []
    let swr1 = this.channelApi.getChannelsForTeam(this.currentProjectId || null, false)
    let swr2 = this.currentProjectId ? this.channelApi.getChannelsForTeam(null, false) : undefined
    if (swr1.data && (!swr2 || swr2.data)) {
      channels.push(...swr1.data)
      if (swr2?.data) channels.push(...swr2.data.filter(c => c.isDirect))
    }
    return channels.filter(c => !c.deleted)
  }

  get allUnreadChannels(): Channel[] {
    return this.channels.filter((ch: Channel) => {
      return ch.unreadMsgCount ? ch.unreadMsgCount > 0 : false
    })
  }

  get mailFolders(): MailFolder[] {
    return this.getAllMailFoldersRecursive(mailFolderServiceApi.getFolders(false).data || [])
  }

  getAllMailFoldersRecursive(folders: MailFolder[]): MailFolder[] {
    if (folders.length < 1) return []

    let inboxes: MailFolder[] = []
    folders.forEach((f: MailFolder) => {
      if (f.subFolders && f.subFolders.length > 0) {
        inboxes = inboxes.concat(this.getAllMailFoldersRecursive(f.subFolders))
      }
      inboxes.push(f)
    })

    return inboxes
  }

  get unreadMessageCount(): number | string {
    let sum = 0
    this.allUnreadChannels.forEach((ch: Channel) => {
      sum += ch.unreadMsgCount || 0
    })
    return sum > 99 ? '99+' : sum
  }

  get unreadEmailCount(): number | string {
    let numberOfUnread: number = 0
    this.mailFolders.forEach((f: MailFolder) => {
      if (!this.isInJunkOrTrash(f, this.mailFolders) && f.numberOfUnread) {
        numberOfUnread += f.numberOfUnread
      }
    })
    if (numberOfUnread) {
      if (numberOfUnread > 99) {
        return "99+"
      } else {
        return numberOfUnread
      }
    } else {
      return 0
    }
  }

  isInJunkOrTrash(f: MailFolder, folders: MailFolder[]): boolean {
    if (['\\Junk', '\\Trash'].includes(f.type || '') ||
      [ 'Junk', 'Trash' ].includes(f.name || '')) {
      return true
    }
    return this.parentIsJunkOrTrash(f, folders)
  }

  parentIsJunkOrTrash(f: MailFolder, folders: MailFolder[]): boolean {
    for (let folder of folders) {
      if (folder.subFolders?.find(s => s.originalId === f.originalId)) {
        return ['\\Junk', '\\Trash'].includes(folder.type || '') ||
          [ 'Junk', 'Trash' ].includes(folder.name || '') ||
          this.parentIsJunkOrTrash(folder, folders)
      }
    }
    return false
  }

  get inboxCount(): number | string {
    let count = this.inbox.length || 0
    if (count > 99) return "99+"
    else return count
  }

  get inbox(): { eventId: string, event: CalendarEvent | null, messages: SchedulingObject[] }[] {
    const swr: SWR<SchedulingObject[], string[]> = calendarInboxServiceApi.readInbox()
    const inbox: Map<string, { eventId: string, event: CalendarEvent, messages: SchedulingObject[] }> = new Map<string, { eventId: string, event: CalendarEvent, messages: SchedulingObject[] }>();
    (swr.data || []).forEach((s: SchedulingObject) => {
      const eventWithRequest: CalendarEvent | undefined = s.eventsFromRequest ? s.eventsFromRequest.find(e => !!e.requestFor) : undefined
      const requestFor: CalendarEvent | null | undefined = eventWithRequest?.requestFor
      if (eventWithRequest && requestFor) {
        const eventId: string = requestFor.originalId || ''
        eventWithRequest.requestFor = requestFor
        let swr: SWR<Event | null, string> | null = null
        if (eventId) {
          swr = eventServiceApi.getEvent(eventId)
          eventWithRequest.requestFor = swr.data || requestFor
        }
        let objects = inbox.get(eventId)
        if (!objects) {
          objects = reactive({
            eventId: eventId,
            event: eventWithRequest,
            messages: []
          })
          inbox.set(eventId, objects)
          if (swr) {
            swr.call?.promise?.finally(() => {
              if (swr) {
                eventWithRequest.requestFor = swr.data || eventWithRequest.requestFor
              }
            })
          }
        } else if (!objects.event) {
          objects.event = eventWithRequest
        }
        objects.messages.push(s)
      } else if (s.eventsFromRequest && s.eventsFromRequest.length > 0) {
        const eventId: string = s.eventsFromRequest[0].uid || ''
        let objects = inbox.get(eventId)
        if (!objects) {
          objects = {
            eventId: eventId,
            event: s.eventsFromRequest[0],
            messages: []
          }
          inbox.set(eventId, objects)
        }
        objects.messages.push(s)
      }
    })
    inbox.forEach(value => {
      value.messages = value.messages.sort((i, j) => {
        if (i.eventsFromRequest && j.eventsFromRequest) {
          const iDate = i.eventsFromRequest[0].stamp || i.eventsFromRequest[0].lastModified
          const jDate = j.eventsFromRequest[0].stamp || j.eventsFromRequest[0].lastModified
          return SortAndFilterUtil.compare(jDate, iDate)
        } else {
          return 0
        }
      })
    })
    return Array.from(inbox.values()).sort((i, j) => {
      if (i.messages && i.messages[0].eventsFromRequest && j.messages && j.messages[0].eventsFromRequest) {
        const iEvents = i.messages[0].eventsFromRequest
        const jEvents = j.messages[0].eventsFromRequest
        if (iEvents && jEvents) {
          const iDate = iEvents[0].stamp || iEvents[0].lastModified
          const jDate = jEvents[0].stamp || jEvents[0].lastModified
          return SortAndFilterUtil.compare(jDate, iDate)
        }
      }
      return 0
    })
  }

  get isAdmin() {
    const isAdmin: boolean = userServiceApi.isAdmin
    if (isAdmin && !this.backupJobsPromise && AdminpanelHolder.integrateAdminpanel && !SettingsUtil.getHideNoBackupJobsWarning()) try {
      this.backupJobsPromise = AdminpanelHolder.instance?.store?.dispatch('GET_BACKUP_JOBS')
      this.backupJobsPromise?.finally(() => {
        AdminpanelHolder.watchers.push(this.backupJobsWatcher)
      })
    } catch (e) {}
    return isAdmin
  }

  get collapseSidebar(): boolean {
    const params = this.$route.params
    let setParams = 0
    for (const param in params) {
      let val = params[param]
      if (!!val) setParams++
    }
    return setParams > 0
  }

  hideBackupJobsWarning() {
    SettingsUtil.setHideNoBackupJobsWarning()
    const watcherIndex = AdminpanelHolder.watchers.indexOf(this.backupJobsWatcher)
    if (watcherIndex >= 0) {
      AdminpanelHolder.watchers.splice(watcherIndex)
    }
    this.showNoBackupsWarning = false
  }

  mounted() {
    //migrationStatusService.start()
    demoService.setIntervalCallback((link: string) => {
      this.demoNaggingPopup.show(link)
    })
  }

  unmounted() {
    demoService.setIntervalCallback(() => {})
    this.focusListener.remove()
  }
}
</script>

<style lang="scss">
@import "node_modules/elly-bs4/sass/variables.scss";
@import "node_modules/elly-bs4/elly.scss";
@import "styles/fontawesome/fontawesome.scss";
@import "styles/fontawesome/solid.scss";
@import "styles/fontawesome/regular.scss";
@import "styles/loading-button.scss";
@import "styles/coreui-icons/icons.scss";
@import "styles/primevue-sass/themes/mytheme/theme.scss";
@import "styles/flatpickr_theme_uniki.scss";


/*for Samsung devices with crazy narrow screens*/
.responsive {
  font-size: 75%;
}

#groupware {
  background-color: $uniki_primary_dark;
  background: url('/images/bg.jpg');
  background-size: cover;
}

@media screen and (min-width: 370px) {
  .responsive {
    font-size: 87.5%;
  }
}

@media screen and (min-width: 1900px) {
  .responsive {
    font-size: 97%;
  }
}

.vh-100 {
  height: 100vh !important;
}

.tox.tox-tinymce {
  border: #1C1B3C solid 2px;
  //border-top: none;
}
.animated-input input.form-control, .btn, .card, .animated-input.ai-filled:before, .v3dp__datepicker input, .multiselect:before, textarea, .custom-checkbox .custom-control-label:before, .tox.tox-tinymce {
  border-radius: 3px;
}

//restyling of primevue:
/*
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: $uniki_primary !important;
}

.p-progressbar .p-progressbar-value {
  background: $uniki_primary !important;
}

.p-progressbar .p-progressbar-label {
  color: #ffffff;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $uniki_primary !important;
  background: $uniki_primary !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: $uniki_primary !important;
  background: $uniki_primary !important;
  color: #ffffff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:focus {
  outline-color: $uniki_primary !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none !important;
  border-color: $uniki_primary !important;
}

.p-timeline .p-timeline-event-marker {
  background-color: $uniki_primary !important;
}

.p-overlaypanel .p-overlaypanel-close {
  background-color: $uniki_primary_dark !important;
}

.p-progressbar {
  height: 0.5rem !important;
}

.p-tree {
  border: none !important;
}

.p-tooltip {
  max-width: 24rem !important;
}
 */

.card-lite, .card-light {
  border: none !important;
}

.tox.tox-silver-sink.tox-tinymce-aux, .tox > .tox-dialog-wrap {
  z-index: 9999 !important;
}

.modal {
  z-index: 7722;
}

[class^="cil-"], [class*=" cil-"] {
  vertical-align: middle !important;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.multiselect-search input {
  background-color: transparent;
}
.multiselect-option.is-pointed {
  color: $uniki_primary_dark;
  background-color: rgba(0, 0, 0, 0.07) !important;
}
.multiselect-option.is-selected {
  color: $uniki_primary_dark;
  background-color: #E1E4F2 !important;
}
.multiselect-tag {
  background: $uniki_primary;
}
.multiselect-input {
  height: 100%;
  font-weight: 600;
  font-size: 0.8rem;
  cursor: text;
  border: none;
  position: relative;
  z-index: 1;
}
.multiselect.is-disabled .multiselect-input {
  cursor: not-allowed;
}
.multiselect-clear {
  background-color: transparent;
}
/*
.p-chips-token {
  background: $uniki_primary !important;
  border-radius: 3px !important;
  z-index: 9999 !important;
  color: white !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.p-chips-input-token {
  z-index: 9999 !important;
  height: 100% !important;
  font-weight: 600 !important;
  font-size: 0.8rem !important;
  cursor: text !important;
  border: none !important;
  position: relative !important;
 }
.p-chips-input-token input {
  color: white !important;
}

.p-dialog {
  max-height: initial !important;
}
.p-dialog .p-dialog-content {
  height: 100%;
}
*/

.p-dialog-content {
  padding: 1.5rem !important;
}

.badge-border-dark {
  border: 1px solid $uniki_primary_dark;
  background-color: $white;
  border-radius: 3px;
}

.collapsible-sidebar {
  padding: 1rem 0.5rem 1rem 1rem;
  width: 16rem;
  height: 100% !important;
  transition: width 1s ease-in-out;
}

.collapsible-sidebar.collapse {
  width: 5rem;
  min-width: 5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

#btn-projectswitch > .menu-toggle {
  width: 5rem;
  min-width: 5rem;
}

@media screen and (min-width:768px){

  #btn-projectswitch > h3 {
    width: 18rem;
    min-width: 18rem;
  }
}


.side-panel{
  position: relative;
  width: 18rem;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  height: 100%;
  background-color: $white;

  //Separator:
  border: none !important;
  border-right: 1px solid #ced4da !important;

  @media screen and (max-width:767px){

    width: 100%;
    height: auto;
    overflow: visible; //Let contained dropdown show
    border-right: none !important;

    & .side-panel-content {
      position: absolute;
      //top: 100%;
      left: 0;
      z-index: 1000;
      background-color: $white;
      overflow: hidden;
      width: 100%;
      box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.2);
      max-height: 0px;
      transition: max-height .25s ease-in-out;
      border-radius: 3px;
      border-bottom: 0.5rem solid white; //This emulates padding without another child div


      &.show {
        max-height: 50vh;
        overflow-y: auto;
      }
    }

  }



  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  .side-panel-menu-item {

    &:hover {
      //color: $uniki_primary;
      cursor: pointer;
      background-color: $uniki_secondary;

      & > a {
        text-decoration: underline;
      }

    }

    &.active {
      border-left: 4px solid $uniki_primary;
      color: $uniki_primary_dark;
      background-color: $uniki_secondary;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;

      &.p-button-icon {
        overflow: visible;
        text-overflow: initial;
      }
    }
  }
}

#btn-projectswitch {

  i {
    margin-top: 2px;
    font-size: 1.5rem;
  }

  &:hover {
    cursor: pointer;

    i {
      color: $uniki_primary !important;
    }

    h4 {
      color: $uniki_primary !important;
    }
  }
}

.input-padding-left-for-icon {
  padding-left: 2rem;
}
//Fix a bug introduced by the old Adminpanel:
:not(:-webkit-autofill) {
  -webkit-animation-name: none;
  animation-name: none;
}

.emoji-picker__wrapper {
  z-index: 32767;
}

.chat-message-body > p {
  margin: 0 !important;
  padding: 0 !important;
}

.p-button {
  min-width: 40px;
  height: 40px;
}

.p-chip-image > img {
  border-radius: 3px !important;
}

.rendered-markdown ul, .rendered-markdown ol {
  padding: 0;
  margin-left: 20px;

  p {
    margin: 0;
  }
}

ul[data-type="taskList"] {
  margin-left: 0;
}

li.taskItem {
  list-style: none;
  display: flex;

  > label {
    flex: 0 0 auto;
    margin-right: 0.5rem;
    user-select: none;
  }

  > div {
    flex: 1 1 auto;
  }
}

.drop-area {
  margin: 0 1rem;
  border-color: $uniki_primary_dark;
  border-style: dashed;
  border-width: 3px;
  border-radius: 3px;
}

.embedded-adminpanel {
  #sidenav {
    display: none;
  }

  #dashboardContent {
    margin-left: 0 !important;
    //background-color: $uniki_secondary;
    //border-radius: 3px;
  }

  #dashboardContent > div {
    padding: 0 !important;
  }

  #dashboardContent div.home, #dashboardContent div.store {
    padding: 0 !important;
  }

  #dashboardContent div.store > div.container > div.bg-white {
    border-radius: 3px;
  }

  #dashboardContent > div.settings, #dashboardContent > div.notificationcenter, #dashboardContent > div.account, #dashboardContent > div.storedetails {
    background-color: $uniki_secondary;
    border-radius: 3px;
  }

  .settings > div > .container-fluid, .notificationcenter > div, .account > .container-fluid, .storedetails > .container-fluid, .storedetails > .container-fluid > div {
    background-color: transparent !important;
  }

  #adminmode-collapse {
    display: none;
  }

  .app-row {
    box-shadow: none !important;
    border: none !important;
    border-bottom: 1px solid $separatorColor !important;
    border-radius: 3px !important;
  }

  .app-row .app-text {
    box-shadow: none !important;
    border-radius: 3px !important;
  }

  .app-row .app-image {
    padding: 12px !important;
  }

}

body {
  color: #1c1b3c;
  text-rendering: optimizeLegibility;
}

#topmenubar button {
  margin-top: 0.5rem; //mt-2
}

.logo-container {
  width: 5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width:767px){

  #navbar-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $uniki_primary_dark;
    z-index: 9999;
    padding-bottom: 0.5rem;
  }

  #navbar-list > div {
    flex-direction: column;
  }

  #searchbar {
    width: 100%;
  }
}

.migration-banner-close {
  position: absolute;
  top: 8px;
  right: 8px;
  color: white;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
}

.h-0 {
  max-height: 0;
  overflow: hidden;
}
.h-auto {
  max-height: 100vh;
  overflow: hidden;
}

.strike-through .fc-event-title {
  text-decoration: line-through;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-0 {
  opacity: 0;
}

.table-column-name {
  cursor: pointer;
}

.table-column-name:hover {
  color: $uniki_primary;
}

.p-chip-remove-icon:before {
  vertical-align: middle;
}

.tiptap-content > .ProseMirror.custom-editor {
  min-height: 100%;
  padding: 1rem;
}

h6,.h6 {
  font-size: 1.07rem;
}

</style>
