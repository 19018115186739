<template>
  <div class="h-100 p-2">
    <div class="d-flex flex-row h-100 bg-light" style="border-radius: 3px; overflow: auto">
      <div class="container-fluid container-md p-4 p-md-6 ml-md-0">
        <h2>
          <translate translate-context="title">
            Import PST File
          </translate>
        </h2>
        <p>
          <b><translate>What you can do here:</translate></b>&nbsp;<translate>
            Import a PST File containing Emails, Calendars and Contacts.
            You can select the User to import the data for and the folder from the pst file to import. Note: This import only works with PST Files smaller than 5GB. For bigger Imports please contact Uniki.
          </translate>
        </p>
        <p><b><translate>What you can't do here:</translate></b>&nbsp;<translate>Backup your PST Files or Import Mails from IMAP Accounts directly.</translate></p>
        <div v-if="loading">
          <Skeleton class="mt-6" height="40px" style="width: 80%" />
        </div>
        <div v-else class="row mt-6 mb-5">
          <div class="col-auto">
            <Button
              :label="i18n.$gettext('Upload new File')"
              icon="cil-plus"
              class="p-button btn-raised mr-3"
              @click="openUploadDialog"
            />
          </div>
        </div>
        <div v-if="files.length > 0 && !loading" class="row">
          <div class="col">
            <div v-for="file in files" :key="file.name" class="p-4 my-1 card separator-bottom">
              <div class="d-flex flex-row w-100">
                <div class="flex-grow-1" style="min-width: 0">
                  <p class="lead text-dark font-weight-bold">
                    {{ file.name }}
                  </p>
                  <p> {{ getFileSizeHumanReadable(file.size) }}; {{ getHumanReadableDate(file.mdate) }}</p>
                </div>
                <Button
                  v-tooltip="i18n.$gettext('Import')"
                  icon="cil-envelope-plus"
                  class="p-button p-button-primary mr-3"
                  @click="openImportDialog(file)"
                />
                <Button
                  v-tooltip="i18n.$gettext('Remove file')"
                  icon="cil-trash"
                  class="p-button p-button-danger mr-3"
                  @click="openDeleteConfirm(file.name)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        v-model:visible="showUploadModal"
        :header="i18n.$gettext('Upload PST Files')"
        :modal="true"
        :draggable="false"
      >
        <div>
          <p>Please select and upload your files. After the upload has finished, the modal will close automatically.</p>
          <FileUpload
            name="file"
            url="/groupware-api/v1/pstupload"
            :multiple="true"
            accept=".pst"
            @before-send="authenticateUpload"
            @upload="onUploadDone"
          >
            <template #empty>
              <p><translate>Drag and drop files to here to upload.</translate></p>
            </template>
          </FileUpload>
        </div>
      </Dialog>
      <Dialog
        v-model:visible="showImportModal"
        :header="i18n.$gettext('Import PST File')"
        :modal="true"
        :draggable="false"
        @hide="closeImportDialog"
      >
        <div>
          <div v-if="contentLoading">
            <Skeleton class="mt-6" height="40px" style="width: 80%" />
          </div>
          <div v-else>
            <p><translate>Please select the folder you want to import. Normally this is the Folder that contains your inbox and all the other folders:</translate></p>
            <TreeSelect
              v-model="selectedRootFolder"
              :options="pstContent"
              selection-mode="single"
              :placeholder="i18n.$gettext('Select an item')"
            />
            <p class="mt-4">
              <translate>Please select the user you want to import this file to:</translate>
            </p>
            <Dropdown
              v-model="selectedUser"
              :options="users"
              option-label="name"
              option-value="id"
              :placeholder="i18n.$gettext('Select a mailbox')"
            />
            <LoadingButton class="mt-4" variant="primary" :action="startImport">
              <translate>Import starten</translate>
            </LoadingButton>
          </div>
        </div>
      </Dialog>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import Skeleton from "primevue/skeleton"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {pSTImportServiceApi} from "@/api/PSTImportServiceApi"
import useToast, {ToastAPI} from "@/util/toasts"
import Dialog from "primevue/dialog"
import FileUpload, {FileUploadBeforeUploadEvent} from "primevue/fileupload"
import {rpcClient} from "@/api/WebsocketClient"
import fileSizeString from "@/util/fileSize"
import dayjs from "@/util/dayjs"
import TreeSelect from "primevue/treeselect"
import LoadingButton from "@/components/common/LoadingButton.vue"
import Dropdown from "@/components/common/Dropdown.vue"
import {useConfirm} from "primevue/useconfirm"
import RpcError from "@/api/RpcError"

@Options({
  //@ts-ignore
  props: {
  },
  components: {
    LoadingButton,
    Button, Skeleton, Dialog, FileUpload, TreeSelect, Dropdown
  }
})
export default class PSTImport extends Vue {

  i18n: Language = useGettext()
  toast: ToastAPI = useToast()
  confirm = useConfirm()

  loading: boolean = false

  showUploadModal: boolean = false
  showImportModal: boolean = false

  elementToImport: { name: string, size: string, mdate: string } | null = null
  contentLoading: boolean = false
  pstContent: any = null
  selectedRootFolder: any = null
  selectedFile: string = ""

  files: { name: string, size: string, mdate: string }[] = []
  users: any[] = []
  selectedUser: string = ""

  mounted(){
    void this.loadData()
  }

  async loadData(){
    this.loading = true
    try {
      this.files = await pSTImportServiceApi._getFiles()
    } catch (e){
      this.toast.error(this.i18n.$gettext("An Error occured while fetching the PST files from the server"))
    }
    try {
      this.users = await pSTImportServiceApi._getAvailableMailboxesForImport()
    } catch (e){
      this.toast.error(this.i18n.$gettext("An Error occured while fetching the PST files from the server"))
    }
    this.loading = false
  }

  resetPstContent() {
    this.selectedFile = ""
    this.pstContent = null
  }

  async loadPstContent(file: string){
    this.contentLoading = true
    try {
      this.selectedFile = file
      this.pstContent = [ await pSTImportServiceApi._getFileContents(file) ]
    } catch (e){
      this.toast.error(this.i18n.$gettext("An Error occured while fetching the PST folders from the server"))
      this.pstContent = null
    } finally {
      this.contentLoading = false
    }
  }

  async startImport(){
    //Get selected node:
    let foldername = ""
    for (const [key, value] of Object.entries(this.selectedRootFolder)) {
      if(value === true){
        foldername = key //use first match
        break
      }
    }

    //Create options:

    const payload : any = {
      "users": {},
      "mailContainer": "de_uniki_mail_emailpostfcher"
    }

    payload.users[this.selectedUser] = {
      "filePath": "~" + this.selectedFile,
      "folderPath": this.foldername !== "" ? foldername: null
    }

    try {
      await rpcClient.getAjaxClient().post("/migration/pst", payload)
      this.toast.success(this.i18n.$gettext("The import will run in the background"), this.i18n.$gettext("PST Import started"))
      this.elementToImport = null
      this.selectedFile = ""
      this.pstContent  = null
    } catch (e){
      this.toast.error(this.i18n.$gettext("Could not start PST File import"))
    }
  }

  get hasElementToImport(): boolean {
    return this.elementToImport !== null
  }

  openUploadDialog(){
    this.showUploadModal = true
  }

  closeImportDialog() {
    this.elementToImport = null
    this.resetPstContent()
  }

  openImportDialog(toImport: { name: string, size: string, mdate: string }){
    this.showImportModal = true
    this.elementToImport = toImport
    void this.loadPstContent(toImport.name)
  }

  authenticateUpload(event: FileUploadBeforeUploadEvent){
    rpcClient.authenticateXHR(event.xhr)
  }

  onUploadDone(){
    this.showUploadModal = false
    void this.loadData()
  }

  getHumanReadableDate(date: string){
    const asDate = new Date(Number.parseInt(date))
    return dayjs(asDate).format("DD.MM.YYYY, HH:mm")
  }

  getFileSizeHumanReadable(num: string) {
    const asNum: number = Number.parseInt(num)
    return fileSizeString(asNum)
  }

  openDeleteConfirm(name: string){
    this.confirm.require({
      message: this.i18n.$gettext("Do you want to delete this PST File?"),
      header: this.i18n.$gettext("Confirmation"),
      icon: 'cil-warning',
      accept: () => {
        pSTImportServiceApi._deleteFile(name).then(() => {
          this.toast.success(this.i18n.$gettext("Delete successful"))
          void this.loadData()
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Failed to delete"))
        })
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
