<template>
  <Container
    v-if="scene && scene.children"
    class="h-100 d-flex overflow-x-auto"
    group-name="cols"
    tag="div"
    orientation="horizontal"
    drag-handle-selector=".column-drag-handle"
    drag-class="shadow"
    @drop="onColumnDrop($event)"
  >
    <Draggable
      v-for="column in scene.children"
      :key="column.id"
      class="flex-shrink-0 task-column bg-light separator-right"
    >
      <div class="h-100 d-flex flex-column">
        <!-- header-->
        <div class="p-3 d-flex separator-bottom bg-white mb-1" @contextmenu="showListContextMenu(column, $event)">
          <h1 class="mb-2 h5 d-inline flex-grow-1" style="text-transform: none !important;">
            {{ column.name }}
          </h1>
          <div class="flex-shrink-0 flex-grow-0">
            <i v-if="!column.readOnly" class="cil-grip-lines column-drag-handle cursor-move" />
          </div>
        </div>
        <div v-if="!(taskSWR?.call?.loading && column.children.length === 0) && !column.readOnly" class="w-100 pt-2 pb-3 px-3">
          <span class="p-float-label w-100 mt-2">
            <InputText
              v-model="column.newTaskSummary"
              class="w-100"
              type="text"
              @keyup.enter="createNewTaskFromColumn(column)"
            />
            <label><translate>Type to create task</translate></label>
          </span>
        </div>

        <!-- column -->
        <Container
          :ref="el => { addColumn(column.id, el) }"
          :key="column.id"
          class="flex-grow-1 overflow-y-auto overflow-x-hidden"
          orientation="vertical"
          group-name="col-items"
          non-drag-area-selector=".readOnly"
          :should-accept-drop="(e, payload) => (e.groupName === 'col-items' && !payload.loading)"
          :get-child-payload="getCardPayload(column.id)"
          :drop-placeholder="{ className:
                                 `drop-area`,
                               animationDuration: '200',
                               showOnTop: true }"
          drag-class="shadow bg-color-lightgray
            transition duration-100 ease-in
            transform rotate-3 scale-110"
          drop-class="transition duration-100
            ease-in z-50 transform
            -rotate-2 scale-90"
          @drop="(e) => onCardDrop(column.id, e)"
          @drop-ready="() => onDropReady(column.id)"
        >
          <!-- Items -->
          <KanbanItem
            v-for="item in column.children"
            :key="item.originalId"
            :loading="isLoading(item.originalId)"
            :item="item.task"
            :class="{ 'readOnly': item.readOnly || isLoading(item.originalId), 'h-0': !item.visible }"
            :has-menu="hasTaskMenu(item.task?.originalId)"
            @contextmenu="showTaskContextMenu(item.task, column.name, $event)"
            @click="openTask(item.originalId)"
          />
          <div v-if="taskSWR?.call?.loading">
            <div v-for="idx in skeletonCount()" :key="idx" class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
              <Skeleton height="18px" class="flex-grow-1" />
            </div>
          </div>
        </Container>
      </div>
    </Draggable>
    <div class="flex-shrink-0">
      <Button
        v-if="scene && !scene.readOnly"
        icon="cil-plus"
        class="m-2 p-button-raised"
        :label="i18n.$gettext('New Column')"
        @click="openNewColumnPanel"
      />
      <OverlayPanel ref="newcolumnpanel" :show-close-icon="true">
        <div class="m-2">
          <span class="p-float-label w-100 mb-2">
            <InputText
              v-model="newListName"
              class="w-100"
              type="text"
              @keyup.enter="createNewList"
            />
            <label><translate>Enter List Name</translate></label>
          </span>
          <Button
            icon="cil-plus"
            class="p-button-success p-button-raised m-2"
            :loading="newColumnLoading"
            :label="i18n.$gettext('Add')"
            @click="createNewList"
          />
        </div>
      </OverlayPanel>

      <!-- <AnimatedInput v-model="newListName" @submit="createNewList" :placeholder="i18n.$gettext('Create New List...')"></AnimatedInput> -->
    </div>
    <ContextMenu ref="contextMenu" :key="JSON.stringify(menuItems)" :model="menuItems" />
    <Menu ref="menu" :model="menuItems" :popup="true" />
  </Container>
</template>

<script lang="ts">
import {Container, Draggable} from 'vue3-smooth-dnd'
import {applyDrag} from '@/util/applyDrag.ts'
import KanbanItem from './TaskCard.vue'
import {Options, Vue} from "vue-class-component"
import TaskBoard from "@/model/directory/TaskBoard"
import Task from "@/model/entry/Task"
import {taskServiceApi} from "@/api/TaskServiceApi"
import SWR from "@/api/SWR"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import ContextMenu from "primevue/contextmenu"
import {reactive, ref} from "@vue/reactivity"
import {taskBoardServiceApi} from "@/api/TaskBoardServiceApi"
import TaskBoardMetaInformation from "@/model/common/TaskBoardMetaInformation"
import TaskBoardList from "@/model/common/TaskBoardList"
import Button from "primevue/button"
import OverlayPanel from "primevue/overlaypanel"
import InputText from "primevue/inputtext"
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import Skeleton from "primevue/skeleton"
import {useConfirm} from "primevue/useconfirm"
import Menu from "primevue/menu"
import {taskStore} from "@/store/TaskStore"
import {rpcClient} from "@/api/WebsocketClient"
import Organizer from "@/model/common/caldav/Organizer"
import InputSwitch from "primevue/inputswitch"
import Dropdown from "@/components/common/Dropdown.vue"
import SearchBar from "@/components/common/SearchBar.vue"
import Query from "@/model/common/Query"
import {toTask} from "@/router"

@Options({
  components: {
    AnimatedInput, Container, Draggable, KanbanItem, ContextMenu, Button, OverlayPanel, InputText,
    Skeleton, Menu, InputSwitch, Dropdown, SearchBar
  },
  //@ts-ignore
  props: {
    taskBoard: [ TaskBoard, Object ],
    searchQuery: {
      type: [ Query, Object ],
      default: null
    }
  },
  emits: [ 'updating' ]
})
export default class TaskBoardView extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()

  taskBoard!: TaskBoard
  searchQuery!: Query | null

  taskSWR: SWR<Task[], string[]> | null = null

  menuItems: any[] = []
  newListName = ''

  filterString: string | null = null
  assignedToUser: boolean = false

  refresh: number | boolean = 10000
  loading: string[] = []

  //@ts-ignore
  contextMenu: ContextMenu = ref<ContextMenu | null>(null)
  //@ts-ignore
  menu: Menu = ref<Menu | null>(null)
  columnScroll: Map<any, number> = new Map<any, number>()
  //@ts-ignore
  newcolumnpanel: OverlayPanel = ref<OverlayPanel | null>(null)

  newColumnLoading = false
  activeSceneReference: any = {}

  dragTargetedColumnId: string | null = null
  browserSupportsPassiveScroll: boolean | null = null

  addColumn(id: string, element: Container) {
    //@ts-ignore
    const containerElement: HTMLElement | undefined = element?.containerElement

    if (containerElement) {
      //@ts-ignore
      let options = this.doesBrowserSupportPassiveScroll() ? { passive: true } : false
      containerElement.addEventListener("scroll", () => {
        this.columnScroll.set(id, containerElement.scrollTop)

        if (this.dragTargetedColumnId === id) {
          const dropPlaceHolder: Element | null = document.getElementsByClassName('drop-area')[0] || null
          if (dropPlaceHolder?.parentElement?.parentElement) {
            dropPlaceHolder.parentElement.parentElement.style.transform = 'translateY(' + containerElement.scrollTop + 'px)'
          }
        }

      }, options)
    }
  }

  onDropReady(dragTargetedColumnId: string) {
    if (dragTargetedColumnId != this.dragTargetedColumnId) {
      this.dragTargetedColumnId = dragTargetedColumnId

      const dropPlaceHolder: Element | null = document.getElementsByClassName('drop-area')[0] || null

      const dragTargetedColumnScroll = this.columnScroll.get(this.dragTargetedColumnId)
      if (dropPlaceHolder?.parentElement?.parentElement) {
        dropPlaceHolder.parentElement.parentElement.style.transform = 'translateY(' + dragTargetedColumnScroll + 'px)'
      }
    }
  }

  doesBrowserSupportPassiveScroll() {
    if (this.browserSupportsPassiveScroll !== null) {
      return this.browserSupportsPassiveScroll
    } else {
      let passiveSupported = false

      try {
        const options = {
          get passive() {
            // This function will be called when the browser attempts to access the passive property.
            passiveSupported = true
            return false
          }
        }

        //@ts-ignore
        window.addEventListener("test", null, options)
        //@ts-ignore
        window.removeEventListener("test", null, options)
      } catch (err) {
        passiveSupported = false
      }
      this.browserSupportsPassiveScroll = passiveSupported
      return passiveSupported
    }
  }

  openNewColumnPanel(e: Event){
    this.newcolumnpanel.toggle(e)
  }

  skeletonCount(){
    return [...Array(1 + Math.ceil(Math.random() * 4)).keys()]
  }

  createNewTaskFromColumn(column: any) {
    if (column.newTaskSummary && column.newTaskSummary !== '') {
      this.createNewTask(column.id, column.newTaskSummary)
      column.newTaskSummary = ""
    }
  }

  createNewTask(list: string, summary: string) {
    if (!this.taskBoard.originalId) return
    const task: Task = new Task()
    task.originalParentId = this.taskBoard.originalId
    task.categories = [ list ]
    task.summary = summary
    task.organizer = rpcClient.session.user ? Object.assign(new Organizer(), {
      email: rpcClient.session.user.email,
      name: rpcClient.session.user.userName, //TODO
    }) : null
    const id = Math.random().toString(36).substr(2)
    const boardList: TaskBoardList | undefined = this.taskBoard.meta?.taskLists?.find(l => l.name == list)
    if (boardList) {
      boardList.tasks = boardList.tasks || []
      boardList.tasks.unshift(id)
    }
    task.originalId = id
    this.loading.push(id)
    this.refresh = false //Prevent getTask from being called when we modify the store data
    this.$emit('updating', true)
    taskStore.addOrReplaceTask(task)
    taskServiceApi._addTask(task).then((newId: string) => {
      if (boardList) { //Immediately add the new task to the top of the list
        boardList.tasks = boardList.tasks || []
        boardList.tasks.unshift(newId)
      }
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext('Could not create task'))
    }).finally(() => {
      taskStore.removeTask(id)
      setTimeout(() => {
        this.refresh = 10000
        this.$emit('updating', false)
      }, 10000)
      for( let i = 0; i < this.loading.length; i++) {
        if ( this.loading[i] === task.originalId) {
          this.loading.splice(i, 1)
          break
        }
      }
    })
  }

  onColumnDrop(dropResult: any) {
    const scene = Object.assign({}, this.scene)
    scene.children = applyDrag(scene.children, dropResult)
    this.updateBoardLists(scene)
    taskBoardServiceApi._updateTaskBoard(this.taskBoard).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext('Could not move column'))
    })
  }

  onCardDrop(list: any, dropResult: any) {

    if ((dropResult.removedIndex !== null || dropResult.addedIndex !== null) && dropResult.removedIndex != dropResult.addedIndex) {

      this.refresh = false
      this.$emit('updating', true)

      const scene = Object.assign({}, this.scene)
      const column = scene.children.filter((p: any) => p.id === list)[0]
      const itemIndex = scene.children.indexOf(column)
      const newColumn = Object.assign({}, column)

      newColumn.children = applyDrag(newColumn.children, dropResult)
      scene.children.splice(itemIndex, 1, newColumn)

      const task: Task = dropResult.payload.task

      if (dropResult.addedIndex != null) {
        if (newColumn.taskList?.type === 'DONE') {
          this.askDone(task)
        }

        this.prepareCardDrag(scene, list, task)
        if (task.originalId) {
          this.loading.push(task.originalId)
        }

        //Make sure the "removedIndex" has happened and updated the task's categories before calling the server.
        setTimeout(() => {
          Promise.all([ taskServiceApi._updateTask(task), taskBoardServiceApi._updateTaskBoard(this.taskBoard) ]).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext('Could not move task'))
          }).finally(() => {
            for (let i = 0; i < this.loading.length; i++) {
              if (this.loading[i] === task.originalId) {
                this.loading.splice(i, 1)
                break
              }
            }
            setTimeout(() => {
              this.refresh = 10000
              this.$emit('updating', false)
            }, 1000)
            this.$emit('updating', false)
          })
        }, 500)
      }

      if (dropResult.removedIndex != null && dropResult.addedIndex == null && task.categories) {
        task.categories = task.categories.filter(c => c !== list)
      }
    }
  }

  prepareCardDrag(scene: any, newListName: any, task: Task) {
    if (!this.taskBoard.meta) {
      this.taskBoard.meta = new TaskBoardMetaInformation()
    }
    const lists: TaskBoardList[] = []
    const listNames: string[] = []
    scene.children.forEach((child: any) => {
      const list: TaskBoardList = child.taskList || new TaskBoardList()
      list.name = child.name
      if (list.name) listNames.push(list.name)
      list.tasks = child.children.map((task: any) => task.originalId).filter((id: string) => {
        if (task.originalId != id) return true
        else if (list.name && list.name == newListName) return true
        return false
      })
      lists.push(list)
    })
    if (!task.categories) {
      task.categories = []
    }
    task.categories = task.categories.filter((listName: string) => {
      if (listName == newListName) return true
      else return !listNames.includes(listName)
    })
    if (!task.categories.includes(newListName)) task.categories.push(newListName)

    this.taskBoard.meta.taskLists = lists
  }

  updateBoardLists(scene: any) {
    if (!this.taskBoard.meta) {
      this.taskBoard.meta = new TaskBoardMetaInformation()
    }
    const lists: TaskBoardList[] = []
    scene.children.forEach((child: any) => {
      const list: TaskBoardList = child.taskList || new TaskBoardList()
      list.name = child.name
      list.tasks = child.children.map((task: any) => task.originalId)
      lists.push(list)
    })
    this.taskBoard.meta.taskLists = lists
  }

  getCardPayload (columnId: any) {
    return (index: any) => {
      return this.scene.children.filter((p: any) => p.id === columnId)[0].children[index]
    }
  }

  askDone(task: Task) {
    this.confirm.require({
      //TODO: Aks which other list to move the tasks to?
      message: this.i18n.$gettext('Do you want to mark this task as done?'),
      header: this.i18n.$gettext('Task Done'),
      icon: 'pi pi-task',
      accept: () => {
        task.completed = new Date().toISOString()
        task.percentCompleted = 100
        taskServiceApi._updateTask(task).then((id: string) => {
          this.toast.success(this.i18n.$gettext("Task status set to done"))
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Failed to update task status"))
        })
      },
      reject: () => {

      }
    })
  }

  showListContextMenu(column: any, event: Event) {
    this.menuItems = this.getListMenuItems(column)
    this.menu.hide()
    if (this.menuItems.length > 0) {
      void this.$nextTick(() => {
        this.contextMenu.show(event)
      })
    }
  }

  showListMenu(column: any, event: Event) {
    this.menuItems = this.getListMenuItems(column)
    this.contextMenu.hide()
    if (this.menuItems.length > 0) {
      void this.$nextTick(() => {
        this.menu.toggle(event)
      })
    }
  }

  getListMenuItems(column: any): any[] {
    const menuItems = []
    if (!this.scene.readOnly) {
      menuItems.push({
        label: this.i18n.$gettext('Delete Column'),
        icon:'cil-trash',
        command: () => {
          this.confirm.require({
            //TODO: Aks which other list to move the tasks to?
            message: this.i18n.$gettext('Do you want to delete the list?'),
            header: this.i18n.$gettext('Delete Task List'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              const scene = Object.assign({}, this.scene)
              const columnIndex = scene.children.findIndex((c: any) => c.id === column.id)
              const tasksToUpdate: Task[] = []
              scene.children[columnIndex].children.forEach((child: any) => {
                child.task.categories =  child.task.categories.filter((c: string) => c !== column.id)
                tasksToUpdate.push(child.task as Task)
              })
              scene.children.splice(columnIndex, 1)
              this.updateBoardLists(scene)
              return taskBoardServiceApi._updateTaskBoard(this.taskBoard).then(() => {
                tasksToUpdate.forEach(task => taskServiceApi._updateTask(task))
              })
            },
            reject: () => {

            }
          })
        }
      }, {
        label: this.assignedToUser ? this.i18n.$gettext('Show all tasks') : this.i18n.$gettext('Show my tasks'),
        icon: 'cil-filter',
        command: () => {
          this.assignedToUser = !this.assignedToUser
        }
      }, {
        label: this.i18n.$gettext("Sort by"),
        icon: 'cil-sort-alpha-down',
        items: [/*{
          label: this.i18n.$gettext('Last modified Asc'),
          icon:'cil-sort-ascending',
          command: () => {
            this.sort(column.id, 'modified')
          }
        },{
          label: this.i18n.$gettext('Last modified Desc'),
          icon:'cil-sort-descending',
          command: () => {
            this.sort(column.id, 'modified-reverse')
          }
        },*/{
          label: this.i18n.$gettext('Priority Desc'),
          icon:'cil-sort-descending',
          command: () => {
            this.sort(column.id, 'prio')
          }
        },{
          label: this.i18n.$gettext('Priority Asc'),
          icon:'cil-sort-ascending',
          command: () => {
            this.sort(column.id, 'prio-reverse')
          }
        },{
          label: this.i18n.$gettext('Due Asc'),
          icon:'cil-sort-ascending',
          command: () => {
            this.sort(column.id, 'due')
          }
        },{
          label: this.i18n.$gettext('Due Desc'),
          icon:'cil-sort-descending',
          command: () => {
            this.sort(column.id, 'due-reverse')
          }
        }]

      })
    }
    return menuItems
  }

  showTaskContextMenu(task: Task, columnName: string, event: Event) {
    this.menuItems = this.getTaskMenuItems(task, columnName)
    if (this.menuItems.length > 0) {
      void this.$nextTick(() => {
        this.contextMenu.show(event)
      })
    }
  }

  hasTaskMenu(taskOriginalId: string): boolean {
    return Boolean(taskOriginalId && !this.scene.readOnly)
  }

  getTaskMenuItems(task: Task, columnName: string): any[] {
    const menuItems = []
    if (task.originalId && !this.scene.readOnly) {
      const id = task.originalId
      menuItems.push({
        label: this.i18n.$gettext('Duplicate'),
        icon:'cil-copy',
        command: () => {
          const copy: Task = Object.assign(new Task(), task)
          copy.uid = null
          copy.lastModified = null
          copy.created = null
          copy.stamp = null
          copy.syncTag = null
          copy.sequence = 0
          copy.organizer = rpcClient.session.user ? Object.assign(new Organizer(), {
            email: rpcClient.session.user.email,
            name: rpcClient.session.user.userName, //TODO
          }) : null
          const boardList: TaskBoardList | undefined = this.taskBoard.meta?.taskLists?.find(l => l.name == columnName)
          taskServiceApi._addTask(copy).then((newId: string) => {
            if (boardList) { //Immediately add the new task to the top of the list
              boardList.tasks = boardList.tasks || []
              boardList.tasks.unshift(newId)
            }
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext('Could not duplicate task'))
          })
        }
      })
      menuItems.push({
        label: this.i18n.$gettext('Delete'),
        icon:'cil-trash',
        command: () => {
          this.confirm.require({
            message: this.i18n.$gettext('Do you want to delete this task?'),
            header: this.i18n.$gettext('Delete Task'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              taskServiceApi._deleteTask(id).then(() => {
                this.toast.success(this.i18n.$gettext("Task deleted"))
              }).catch((e: RpcError) => {
                this.toast.error(e.message, this.i18n.$gettext('Could not delete task'))
              })
            },
            reject: () => {

            }
          })
        }
      })

      const writable = ['WRITE', 'OWNER']
      const boards: TaskBoard[] = (taskBoardServiceApi.getTaskBoards(false).data || []).filter(c => !c.shareAccess || writable.includes(c.shareAccess))
      if (boards && boards.length > 1) {
        menuItems.push({
          label: this.i18n.$gettext('Move to...'),
          icon: 'cil-move',
          items: boards.map((board: TaskBoard) => {
            const taskLists: TaskBoardList[] = board.meta?.taskLists || []
            const defaultList: TaskBoardList | undefined =
              taskLists.find(list => list.type === 'BACKLOG') ||
              taskLists.find(list => list.type === 'DEFAULT') ||
              taskLists.find(list => list.type === 'INBOX')
            return {
              label: board.name,
              icon:'cil-task',
              items: taskLists.map((list: TaskBoardList) => {
                return {
                  label: list.name,
                  icon: '',
                  command: () => {
                    task.originalParentId = board.originalId
                    task.categories = task.categories || []
                    const categoryIdx: number = task.categories.indexOf(columnName)
                    if (categoryIdx >= 0) {
                      task.categories.splice(categoryIdx, 1)
                    }
                    if (list.name) {
                      task.categories.push(list.name)
                    }
                    taskServiceApi._updateTask(task).then(() => {
                      this.toast.success(this.i18n.$gettext("Task moved"))
                    }).catch((e: RpcError) => {
                      this.toast.error(e.message, this.i18n.$gettext("Task could not be moved"))
                    })
                  }
                }
              }),
              command: () => {
                task.originalParentId = board.originalId
                task.categories = task.categories || []
                const categoryIdx: number = task.categories.indexOf(columnName) || -1
                if (categoryIdx >= 0) {
                  task.categories.splice(categoryIdx, 1)
                }
                if (defaultList?.name) {
                  task.categories.push(defaultList.name)
                }
                taskServiceApi._updateTask(task).then(() => {
                  this.toast.success(this.i18n.$gettext("Task moved"))
                }).catch((e: RpcError) => {
                  this.toast.error(e.message, this.i18n.$gettext("Task could not be moved"))
                })
              }
            }
          })
        })
      }
    }
    return menuItems
  }

  createNewList(): Promise<any> {
    if (!this.taskBoard.meta) {
      this.taskBoard.meta = new TaskBoardMetaInformation()
    }
    if (!this.taskBoard.meta.taskLists) {
      this.taskBoard.meta.taskLists = []
    }
    //Check for name conflicts:
    let hasNameConflict: boolean = false
    this.taskBoard.meta.taskLists.forEach( (list: TaskBoardList) => {
      if (list.name?.toLowerCase() === this.newListName.toLowerCase()) {
        hasNameConflict = true
      }
    })

    if (hasNameConflict) {
      this.toast.error(this.i18n.$gettext("Two lists in one board cannot have the same name."))
      return Promise.reject()
    }

    let list = new TaskBoardList()
    list.name = this.newListName
    this.newColumnLoading = true
    this.taskBoard.meta.taskLists.push(list)
    return taskBoardServiceApi._updateTaskBoard(this.taskBoard).then(() => {
      this.newcolumnpanel.hide()
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not create tasklist"))
    }).finally(() => {
      this.newColumnLoading = false
      this.newListName = ''
    })
  }

  matchesFilter(assignedToUser: boolean, filter: string | null, task: Task): boolean {
    const userEmail: string = rpcClient.session.user?.email ?? ""
    if (assignedToUser && userEmail != undefined && userEmail !== "") {
      let matches: boolean = false
      if (task.attendees) {
        for (const attendee of task.attendees) {
          if (attendee.email && attendee.email == userEmail) {
            matches = true
            break
          }
        }
      }
      if (!matches) {
        return false
      }
    }
    if (filter && filter.trim() !== '') {
      return !!task.summary?.includes(filter.trim())
    }
    return true
  }

  //TODO define class for card instead of any
  sort(columnName: string, order: string): void {
    const scene = Object.assign({}, this.scene)
    const column = scene.children.filter((p: any) => p.id === columnName)[0]

    const sortByPrio = function (card1: any, card2: any) {
      let t1: Task = card1.task
      let t2: Task = card2.task
      let p1 = t1.priority == 'HIGH' ? 0 : (t1.priority == 'MEDIUM' ? 1 : (t1.priority == 'LOW' ? 2 : 5))
      let p2 = t2.priority == 'HIGH' ? 0 : (t2.priority == 'MEDIUM' ? 1 : (t2.priority == 'LOW' ? 3 : 5))
      //return p1 > p2 ? 1 : (p1 < p2 ? -1 : 0)
      return p1 - p2
    }

    const sortByDate = function (card1: any, card2: any) {
      let t1: Date = card1.task.lastModified ? new Date(card1.task.lastModified) : (card1.task.created ? new Date(card1.task.created) : new Date(0))
      let t2: Date = card2.task.lastModified ? new Date(card2.task.lastModified) : (card2.task.created ? new Date(card2.task.created) : new Date(0))

      return t1 > t2 ? 1 : (t1 < t1 ? -1 : 0)
    }

    const sortByDue = function (card1: any, card2: any) {
      if (card1.task.due && card2.task.due) {
        let t1: Date = new Date(card1.task.due)
        let t2: Date = new Date(card2.task.due)
        return t1 > t2 ? 1 : (t1 < t2 ? -1 : 0)
      } else if (card1.task.due) {
        return -1
      } else if (card2.task.due) {
        return 1
      } else {
        return 0
      }
    }

    switch (order) {
      case 'modified-reverse':
        column.children.sort(sortByDate).reverse()
        break
      case 'prio':
        column.children.sort(sortByPrio)
        break
      case 'prio-reverse':
        column.children.sort(sortByPrio).reverse()
        break
      case 'due':
        column.children.sort(sortByDue)
        break
      case 'due-reverse':
        column.children.sort(sortByDue).reverse()
        break
      case '':
      case 'modified':
      default:
        column.children.sort(sortByDate)
    }

    this.updateBoardLists(scene)
    this.refresh = false
    this.$emit('updating', true)
    void taskBoardServiceApi._updateTaskBoard(this.taskBoard).finally(() => {
      setTimeout(() => {
        this.refresh = 10000
        this.$emit('updating', false)
      }, 1000)
      this.$emit('updating', false)
    })
  }

  get scene(): any | null {
    const originalBoardId = this.taskBoard.originalId
    if (originalBoardId) {
      if (this.searchQuery) {
        this.taskSWR = taskServiceApi.queryTasks(this.searchQuery, this.refresh ? undefined : false)
      } else {
        this.taskSWR = taskServiceApi.getTasks(originalBoardId, this.refresh ? undefined : false) //Must be false while updating board lists...
        this.taskSWR.call?.promise?.then((ids: string[]) => {
          //Replace the state, to make sure no deleted tasks remain in it
          const tasks = [...taskStore.state.tasks.values()]
          taskStore.replaceTasks(tasks.filter(task => (task.originalParentId !== originalBoardId) || ids.includes(task.originalId || '')))
        })
      }
      if (this.taskSWR.data) {
        let tasks: Task[] = [...this.taskSWR.data]
        if (!this.taskBoard.meta) {
          this.taskBoard.meta = new TaskBoardMetaInformation()
        }
        const taskLists: TaskBoardList[] = this.taskBoard.meta.taskLists || []
        const defaultList: TaskBoardList = taskBoardServiceApi.getDefaultTaskList(this.taskBoard, this.i18n.$gettext('Uncategorized'))

        const readOnly: boolean = Boolean(this.searchQuery || !['WRITE', 'OWNER'].includes(this.taskBoard.shareAccess || ''))
        const scene: any = {
          type: 'container',
          //@ts-ignore
          props: {
            orientation: 'horizontal'
          },
          readOnly: readOnly,
          children: taskLists.map((taskList: TaskBoardList) => {
            const list = taskList.name || ""
            const tasksInList: Task[] = tasks.filter((task: Task) => {
              return task.categories ? task.categories.some(category => {
                return category.toLowerCase() === (taskList.name as string).toLowerCase()
              }) : false
            })
            for (const task of tasksInList) {
              tasks.splice(tasks.indexOf(task), 1)
            }
            const taskOrder: string[] = taskList.tasks || []
            const children: any[] = []

            for (const taskOriginalId of taskOrder) {
              const index = tasksInList.findIndex(task => task.originalId === taskOriginalId)
              if (index >= 0) {
                const task: Task = tasksInList[index]
                children.push({
                  type: 'draggable',
                  originalId: task.originalId,
                  loading: false,
                  readOnly: readOnly,
                  task: task,
                  visible: this.matchesFilter(this.assignedToUser, this.filterString, task)
                })
                tasksInList.splice(index, 1)
              }
            }
            for (const task of tasksInList) {
              children.push({
                type: 'draggable',
                originalId: task.originalId,
                loading: false,
                readOnly: readOnly,
                task: task,
                visible: this.matchesFilter(this.assignedToUser, this.filterString, task)
              })
            }
            const oldSummary = this.activeSceneReference?.children?.find((col: any) => { return col.id === list})?.newTaskSummary || ""
            return reactive({
              id: list,
              type: 'container',
              name: list,
              //@ts-ignore
              props: {
                orientation: 'vertical',
              },
              newTaskSummary: oldSummary,
              readOnly: readOnly,
              children: children,
              taskList: taskList
            })
          })
        }

        if (tasks.length > 0) { //There are some uncategorized tasks left
          const taskOrder: string[] = defaultList.tasks || []
          const children: any[] = []
          for (const taskOriginalId of taskOrder) {
            const index = tasks.findIndex(task => task.originalId === taskOriginalId)
            if (index >= 0) {
              const task: Task = tasks[index]
              children.push({
                type: 'draggable',
                originalId: task.originalId,
                loading: false,
                readOnly: readOnly,
                task: task,
                visible: this.matchesFilter(this.assignedToUser, this.filterString, task)
              })
              tasks.splice(index, 1)
            }
          }
          for (const task of tasks) {
            children.push({
              type: 'draggable',
              originalId: task.originalId,
              loading: false,
              readOnly: readOnly,
              task: task,
              visible: this.matchesFilter(this.assignedToUser, this.filterString, task)
            })
          }
          const existingList: any | undefined = scene.children.find((list: any) => list.taskList === defaultList)
          if (existingList) {
            for (const child of children) {
              existingList.children.push(child)
            }
          } else {
            scene.children.push({
              id: defaultList.name || '',
              type: 'container',
              name: defaultList.name || '',
              //@ts-ignore
              props: {
                orientation: 'vertical',
              },
              children: children,
              taskList: defaultList
            })
          }
        }

        this.activeSceneReference = scene
        return scene
      }
    } else {
      return null
    }
  }

  isLoading(id: string): boolean {
    return this.loading.includes(id)
  }

  openTask(id: string) {
    if (this.isLoading(id) || !this.taskBoard.originalId) return
    toTask(this.taskBoard.originalId, id)
  }
}
</script>
<style scoped lang="scss">
@import "node_modules/elly-bs4/sass/variables.scss";

/** NB: dont remove,
* When using orientation="horizontal" it auto sets "display: table"
* In this case we need flex and not display table
*/
.smooth-dnd-container.horizontal{
  display: flex !important;
}

.cursor-move {
  cursor: move;
}

.task-column {
  min-width: 24rem;
  width: 24rem;
}
</style>
