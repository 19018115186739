
import {Options, Vue} from 'vue-class-component'
import LoginForm from './components/LoginForm.vue'
import {rpcClient} from '@/api/WebsocketClient'
import SideBar from "@/components/SideBar.vue"
import MenuBar from "@/components/common/MenuBar.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import ConfirmDialog from "primevue/confirmdialog"
import Button from "primevue/button"
import Avatar from "@/components/common/Avatar.vue"
import InputText from "primevue/inputtext"
import AutoComplete from "primevue/autocomplete"
import Adminpanel from "@/components/Adminpanel.vue"
import AdminpanelHolder from "@/util/AdminpanelHolder"
import Toast from "primevue/toast"
import SearchBar from "@/components/common/SearchBar.vue"
import Channel from "@/model/directory/Channel"
import {projectServiceApi} from "@/api/ProjectServiceApi"
import {channelServiceApi} from "@/api/ChannelServiceApi"
import MailFolder from "@/model/directory/MailFolder"
import {mailFolderServiceApi} from "@/api/MailFolderServiceApi"
import desktopNotificationUtil from "@/util/DesktopNotificationUtil"
import Dialog from "primevue/dialog"
import {Watch} from "vue-property-decorator"
import HelpCenter from "@/components/helpcenter/HelpCenter.vue"
import Fieldset from "primevue/fieldset"
import migrationStatusService, {MigrationStatus} from "@/util/migrationStatus"
import DemoNaggingPopup from "@/components/DemoNaggingPopup.vue"
import {reactive, ref} from "@vue/reactivity"
import demoService from "@/util/demoService"
import SettingsUtil from "@/util/SettingsUtil"
import {Router, useRouter} from "vue-router"
import {calendarInboxServiceApi} from "@/api/CalendarInboxServiceApi"
import FocusListener from "@/util/focusUtil"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import SWR from "@/api/SWR"
import CalendarEvent from "@/model/entry/Event"
import SchedulingObject from "@/model/SchedulingObject"
import Event from "@/model/entry/Event"
import {eventServiceApi} from "@/api/EventServiceApi"
import {userServiceApi} from "@/api/UserServiceApi"
import {taskServiceApi} from "@/api/TaskServiceApi"
import 'flatpickr/dist/flatpickr.css'


@Options({
  components: {
    DemoNaggingPopup,
    //@ts-ignore
    SearchBar, Adminpanel, LoginForm, SideBar, MenuBar, ConfirmDialog, Button, Avatar, InputText, AutoComplete, Toast, Dialog, HelpCenter, Fieldset
  }
})
export default class App extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient
  channelApi = channelServiceApi
  showModalForNativeNotify: boolean = false
  showHelpOverlay: boolean = false
  showNoBackupsWarning: boolean = false

  backupJobsWatcher = (state: any) => {
    if (SettingsUtil.getHideNoBackupJobsWarning()) {
      const watcherIndex = AdminpanelHolder.watchers.indexOf(this.backupJobsWatcher)
      if (watcherIndex >= 0) {
        AdminpanelHolder.watchers.splice(watcherIndex)
      }
      this.showNoBackupsWarning = false
    } else if (Array.isArray(state.backupJob.backupJobs) && state.backupJob.backupJobs.length === 0 &&
      this.isAdmin && AdminpanelHolder.instance?.store?.getters?.loggedInIsSigning) {
      const path = this.router?.currentRoute?.path || this.router?.currentRoute?.value?.path
      this.showNoBackupsWarning = !path?.endsWith('/settings/backup') && !SettingsUtil.getHideNoBackupJobsWarning()
    }
  }
  backupJobsPromise: Promise<any> | null = null
  //@ts-ignore
  demoNaggingPopup: DemoNaggingPopup = ref(null)

  focusListener: FocusListener = new FocusListener(() => {
    //Just trigger the refresh:
    channelServiceApi.getChannelsForTeam(this.currentProjectId, 20000)
    mailFolderServiceApi.getFolders(20000)
    calendarInboxServiceApi.readInbox(20000)
    //TODO update tasks count without triggering getTasks during tasks drag&drop action
  })

  get shouldShowModalForNotifications(){
    return desktopNotificationUtil.shouldAskForNotificationPermission && this.showModalForNativeNotify
  }

  @Watch('shouldAskForNotificationPermission')
  reactOnGivenNotificationPermission(){
    this.showModalForNativeNotify = false
  }

  get integrateAdminpanel() {
    return AdminpanelHolder.integrateAdminpanel
  }

  get migrationStatus(): MigrationStatus {
    return migrationStatusService.migrationStatus
  }

  get hasAnyMigrationStatus(): boolean {
    return migrationStatusService.hasAnyMigrationStatus
  }

  get hasActiveMigration(): boolean {
    return migrationStatusService.hasMigrationStatus
  }

  get showMigrationBanner(): boolean {
    return rpcClient.fullyLoggedIn && this.hasActiveMigration
  }

  get shouldAskForNotificationPermission() {
    return desktopNotificationUtil.shouldAskForNotificationPermission
  }

  askForNotification() {
    desktopNotificationUtil.askForNotification()
    this.showModalForNativeNotify = true
  }

  get links() {
    const links: any[] = [
      {
        href: '/',
        icon: 'cil-gauge cil-fw',
        text: this.i18n.$gettext('Dashboard')
      },
      {
        href: '/files',
        icon: 'cil-folder cil-fw',
        text: this.i18n.$gettext('Files')
      },
      {
        href: '/chat',
        icon: 'cil-chat-square cil-fw',
        text: this.i18n.$gettext('Chat'),
        notificationCount: this.unreadMessageCount
      },
      {
        href: '/mail',
        icon: 'cil-mail cil-fw',
        text: this.i18n.$gettext('Mail'),
        notificationCount: this.unreadEmailCount
      },
      {
        href: '/calendar',
        icon: 'cil-calendar cil-fw',
        text: this.i18n.$gettext('Calendar'),
        notificationCount: this.inboxCount
      },
      {
        href: '/tasks',
        icon: 'cil-task cil-fw',
        text: this.i18n.$gettext('Tasks'),
        notificationCount: taskServiceApi.overDueCount
      },
      {
        href: '/contacts',
        icon: 'cil-group cil-fw',
        text: this.i18n.$gettext('Contacts')
      }

    ]
    if (this.integrateAdminpanel) {
      links.push({
        href: '/apps',
        icon: 'cil-applications cil-fw',
        text: this.i18n.$gettext('Applications')
      })
      if (this.isAdmin) {
        links.push({
          href: '/store',
          icon: 'cil-cart cil-fwt',
          text: this.i18n.$gettext('App Store')
        })
      }
    }
    return links
  }

  get projects() {
    return projectServiceApi.getProjects(10000).data || []
  }

  get currentProjectId(): string | null {
    if (this.projects && this.projects.length > 0) {
      return this.projects[0].id
    } else {
      return null
    }
  }

  get channels(): Channel[] {
    const channels = []
    let swr1 = this.channelApi.getChannelsForTeam(this.currentProjectId || null, false)
    let swr2 = this.currentProjectId ? this.channelApi.getChannelsForTeam(null, false) : undefined
    if (swr1.data && (!swr2 || swr2.data)) {
      channels.push(...swr1.data)
      if (swr2?.data) channels.push(...swr2.data.filter(c => c.isDirect))
    }
    return channels.filter(c => !c.deleted)
  }

  get allUnreadChannels(): Channel[] {
    return this.channels.filter((ch: Channel) => {
      return ch.unreadMsgCount ? ch.unreadMsgCount > 0 : false
    })
  }

  get mailFolders(): MailFolder[] {
    return this.getAllMailFoldersRecursive(mailFolderServiceApi.getFolders(false).data || [])
  }

  getAllMailFoldersRecursive(folders: MailFolder[]): MailFolder[] {
    if (folders.length < 1) return []

    let inboxes: MailFolder[] = []
    folders.forEach((f: MailFolder) => {
      if (f.subFolders && f.subFolders.length > 0) {
        inboxes = inboxes.concat(this.getAllMailFoldersRecursive(f.subFolders))
      }
      inboxes.push(f)
    })

    return inboxes
  }

  get unreadMessageCount(): number | string {
    let sum = 0
    this.allUnreadChannels.forEach((ch: Channel) => {
      sum += ch.unreadMsgCount || 0
    })
    return sum > 99 ? '99+' : sum
  }

  get unreadEmailCount(): number | string {
    let numberOfUnread: number = 0
    this.mailFolders.forEach((f: MailFolder) => {
      if (!this.isInJunkOrTrash(f, this.mailFolders) && f.numberOfUnread) {
        numberOfUnread += f.numberOfUnread
      }
    })
    if (numberOfUnread) {
      if (numberOfUnread > 99) {
        return "99+"
      } else {
        return numberOfUnread
      }
    } else {
      return 0
    }
  }

  isInJunkOrTrash(f: MailFolder, folders: MailFolder[]): boolean {
    if (['\\Junk', '\\Trash'].includes(f.type || '') ||
      [ 'Junk', 'Trash' ].includes(f.name || '')) {
      return true
    }
    return this.parentIsJunkOrTrash(f, folders)
  }

  parentIsJunkOrTrash(f: MailFolder, folders: MailFolder[]): boolean {
    for (let folder of folders) {
      if (folder.subFolders?.find(s => s.originalId === f.originalId)) {
        return ['\\Junk', '\\Trash'].includes(folder.type || '') ||
          [ 'Junk', 'Trash' ].includes(folder.name || '') ||
          this.parentIsJunkOrTrash(folder, folders)
      }
    }
    return false
  }

  get inboxCount(): number | string {
    let count = this.inbox.length || 0
    if (count > 99) return "99+"
    else return count
  }

  get inbox(): { eventId: string, event: CalendarEvent | null, messages: SchedulingObject[] }[] {
    const swr: SWR<SchedulingObject[], string[]> = calendarInboxServiceApi.readInbox()
    const inbox: Map<string, { eventId: string, event: CalendarEvent, messages: SchedulingObject[] }> = new Map<string, { eventId: string, event: CalendarEvent, messages: SchedulingObject[] }>();
    (swr.data || []).forEach((s: SchedulingObject) => {
      const eventWithRequest: CalendarEvent | undefined = s.eventsFromRequest ? s.eventsFromRequest.find(e => !!e.requestFor) : undefined
      const requestFor: CalendarEvent | null | undefined = eventWithRequest?.requestFor
      if (eventWithRequest && requestFor) {
        const eventId: string = requestFor.originalId || ''
        eventWithRequest.requestFor = requestFor
        let swr: SWR<Event | null, string> | null = null
        if (eventId) {
          swr = eventServiceApi.getEvent(eventId)
          eventWithRequest.requestFor = swr.data || requestFor
        }
        let objects = inbox.get(eventId)
        if (!objects) {
          objects = reactive({
            eventId: eventId,
            event: eventWithRequest,
            messages: []
          })
          inbox.set(eventId, objects)
          if (swr) {
            swr.call?.promise?.finally(() => {
              if (swr) {
                eventWithRequest.requestFor = swr.data || eventWithRequest.requestFor
              }
            })
          }
        } else if (!objects.event) {
          objects.event = eventWithRequest
        }
        objects.messages.push(s)
      } else if (s.eventsFromRequest && s.eventsFromRequest.length > 0) {
        const eventId: string = s.eventsFromRequest[0].uid || ''
        let objects = inbox.get(eventId)
        if (!objects) {
          objects = {
            eventId: eventId,
            event: s.eventsFromRequest[0],
            messages: []
          }
          inbox.set(eventId, objects)
        }
        objects.messages.push(s)
      }
    })
    inbox.forEach(value => {
      value.messages = value.messages.sort((i, j) => {
        if (i.eventsFromRequest && j.eventsFromRequest) {
          const iDate = i.eventsFromRequest[0].stamp || i.eventsFromRequest[0].lastModified
          const jDate = j.eventsFromRequest[0].stamp || j.eventsFromRequest[0].lastModified
          return SortAndFilterUtil.compare(jDate, iDate)
        } else {
          return 0
        }
      })
    })
    return Array.from(inbox.values()).sort((i, j) => {
      if (i.messages && i.messages[0].eventsFromRequest && j.messages && j.messages[0].eventsFromRequest) {
        const iEvents = i.messages[0].eventsFromRequest
        const jEvents = j.messages[0].eventsFromRequest
        if (iEvents && jEvents) {
          const iDate = iEvents[0].stamp || iEvents[0].lastModified
          const jDate = jEvents[0].stamp || jEvents[0].lastModified
          return SortAndFilterUtil.compare(jDate, iDate)
        }
      }
      return 0
    })
  }

  get isAdmin() {
    const isAdmin: boolean = userServiceApi.isAdmin
    if (isAdmin && !this.backupJobsPromise && AdminpanelHolder.integrateAdminpanel && !SettingsUtil.getHideNoBackupJobsWarning()) try {
      this.backupJobsPromise = AdminpanelHolder.instance?.store?.dispatch('GET_BACKUP_JOBS')
      this.backupJobsPromise?.finally(() => {
        AdminpanelHolder.watchers.push(this.backupJobsWatcher)
      })
    } catch (e) {}
    return isAdmin
  }

  get collapseSidebar(): boolean {
    const params = this.$route.params
    let setParams = 0
    for (const param in params) {
      let val = params[param]
      if (!!val) setParams++
    }
    return setParams > 0
  }

  hideBackupJobsWarning() {
    SettingsUtil.setHideNoBackupJobsWarning()
    const watcherIndex = AdminpanelHolder.watchers.indexOf(this.backupJobsWatcher)
    if (watcherIndex >= 0) {
      AdminpanelHolder.watchers.splice(watcherIndex)
    }
    this.showNoBackupsWarning = false
  }

  mounted() {
    //migrationStatusService.start()
    demoService.setIntervalCallback((link: string) => {
      this.demoNaggingPopup.show(link)
    })
  }

  unmounted() {
    demoService.setIntervalCallback(() => {})
    this.focusListener.remove()
  }
}
