<template>
  <InnerLayoutWithSidebar
    v-if="hasTasksBooked"
    :header-label="i18n.$gettext('Tasklists')"
    :default-open="openMobileMenuDefault"
    @created-clicked="showNewBoardModal = true"
  >
    <template #side-panel-content>
      <div
        v-for="board in boards"
        :key="board.originalId"
        class="side-panel-menu-item pl-3 pt-2 pb-2 d-flex align-items-center"
        :class="{'active': taskBoardIsActive(board)}"
        @click="goToTaskBoard(board)"
        @contextmenu="showBoardContextMenu(board, $event)"
      >
        <i v-if="!boardToRename || boardToRename.originalId !== board.originalId" class="cil-task collection-icon" />
        <span v-if="!boardToRename || boardToRename.originalId !== board.originalId" class="pl-1 flex-grow-1">{{ board.name }}</span>
        <div v-if="!boardToRename || boardToRename.originalId !== board.originalId" class="h-100 d-flex align-items-center">
          <i
            v-if="board.isShared && board.shareAccess && board.shareAccess !== 'OWNER'"
            v-tooltip="i18n.$gettext('This Task Board is shared to you by ') + board.ownerId + (board.shareAccess === 'READ' ? ' ' + i18n.$gettext('(read only)') : '')"
            class="cil-share-alt mr-2"
          />
          <Button
            v-if="getBoardMenuItems(board).length > 0"
            icon="cil-menu"
            class="p-button-text p-button-sm context-menu-btn"
            @click.stop.prevent="showBoardMenu(board, $event)"
          />
        </div>
        <div v-else class="d-flex w-100 align-items-center">
          <InputText
            v-model="boardToRename.name"
            class="p-inputtext-sm w-100 flex-grow-1"
            @click.stop
            @keyup.enter="saveName"
          />
          <Button
            icon="cil-check-alt"
            :loading="renameLoading"
            class="p-button-text p-button-success p-button-sm"
            @click.stop="saveName"
          />
          <Button icon="cil-x" class="p-button-text p-button-danger p-button-sm" @click.stop="boardToRename = null" />
        </div>
      </div>
      <div v-if="taskBoardsAreLoading">
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
      </div>
      <TokenAttachmentList
        ref="attachmentcontrol"
        v-model="attachments"
        style="display: none"
        @update:modelValue="importICS"
      />
    </template>
    <template #main-content>
      <div v-if="taskBoard" class="mt-3 mt-xl-0 p-0 h-100 flex-grow-1" style="min-width: 1px">
        <TaskBoardView
          class="h-100"
          :task-board="taskBoard"
          :search-query="searchQuery"
          @updating="refresh = $event ? false : 10000"
        />
      </div>
      <teleport to="#menubarcontent">
        <SearchBar
          class="pt-2"
          collection-type="TASK"
          :collection-id="taskBoard ? taskBoardId : null"
          collection-icon="cil cil-task"
          :collection-name="taskBoard ? taskBoard.name : null"
          @search="searchQuery = $event"
        />
      </teleport>

      <DavSharingDialog
        :title="i18n.$gettext('Share Taskboard')"
        :is-task-board="true"
        only-allow-suggestions
        :collection="boardToShare"
        @hide="boardToShare = null"
      />

      <Dialog v-model:visible="showColorPicker">
        <ColorPicker v-model="boardToColorPick.colorHex" @update:modelValue="saveColor" />
      </Dialog>

      <TaskDetails
        :task="task"
        :forbidden-labels="columnNames"
        :board-id="taskBoard?.id"
        position="right"
        @hide="closeTask"
      />

      <Dialog
        v-model:visible="showNewBoardModal"
        :header="i18n.$gettext('Create Taskboard')"
        :draggable="false"
        :modal="true"
      >
        <div>
          <p><translate>Please specify a name for the new Taskboard:</translate></p>
          <span class="p-float-label w-100">
            <InputText
              v-model="newBoardName"
              type="text"
              class="w-100"
              autofocus
              @keyup.prevent.stop.enter="createTaskBoard"
            />
            <label><translate>Name</translate></label>
          </span>
          <div class="d-flex justify-content-end mt-4">
            <Button
              icon="cil-x"
              class="mr-2 p-button-raised p-button-secondary"
              :label="i18n.$gettext('Abort')"
              @click="showNewBoardModal = false"
            />
            <Button
              icon="cil-plus"
              class=" p-button-raised p-button-success"
              :loading="newBoardLoading"
              :label="i18n.$gettext('Add')"
              @click="createTaskBoard"
            />
          </div>
        </div>
      </Dialog>

      <Dialog
          v-model:visible="uploadingICS"
          :header="i18n.$gettext('Uploading ICS file...')"
          :modal="true"
          :closable="false"
          :draggable="false"
      >
        <div class="w-100 d-flex flex-column align-items-center">
          <p>{{ uploadingAttachment.progress }}%</p>
          <ProgressSpinner />
        </div>
      </Dialog>
      <Dialog
          v-model:visible="importingICS"
          :header="i18n.$gettext('Importing tasks...')"
          :modal="true"
          :closable="false"
          :draggable="false"
      >
        <div class="w-100 d-flex flex-column align-items-center">
          <ProgressSpinner />
        </div>
      </Dialog>

      <Menu ref="menu" :model="menuItems" :popup="true" />
      <ContextMenu ref="contextMenu" :key="JSON.stringify(menuItems)" :model="menuItems" />
    </template>
  </InnerLayoutWithSidebar>
  <div v-else class="d-flex flex-row bg-light h-100" style="border-radius: 3px; overflow: hidden">
    <div class="d-flex flex-grow-1 flex-column justify-content-center h-100">
      <div class="text-center">
        <p class="h5 mb-2">
          <translate>Tasks are disabled</translate>
        </p>
        <p><translate>Please talk to your administrator to enable Tasks for your account or purchase an upgrade to your subscription.</translate></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import Task from "../../model/entry/Task"
import TaskBoard from "../../model/directory/TaskBoard"
import { ref } from "@vue/reactivity"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import ContextMenu from "primevue/contextmenu"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Avatar from "@/components/common/Avatar.vue"
import Tree from "@/components/common/Tree.vue"
import SWR from "@/api/SWR"
import TaskBoardView from "@/components/tasks/TaskBoardView.vue"
import {taskBoardServiceApi} from "@/api/TaskBoardServiceApi"
import TaskDetails from "@/components/tasks/TaskDetails.vue"
import {taskServiceApi} from "@/api/TaskServiceApi"
import DavSharingDialog from "@/components/common/DavSharingDialog.vue"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import RpcError from "@/api/RpcError"
import Dialog from "primevue/dialog"
import useToast from "@/util/toasts"
import Skeleton from "primevue/skeleton"
import SettingsUtil from "@/util/SettingsUtil"
import {useConfirm} from "primevue/useconfirm"
import Menu from "primevue/menu"
import ColorPicker from "@/components/common/ColorPicker.vue"
import featureSubset from "@/util/FeatureSubsets"
import InnerLayoutWithSidebar from "@/components/common/InnerLayoutWithSidebar.vue"
import breakpointUtil from "@/util/BreakpointUtil"
import SearchBar from "@/components/common/SearchBar.vue"
import Query from "@/model/common/Query"
import TokenAttachmentList from "@/components/common/TokenAttachmentList.vue"
import ProgressSpinner from "primevue/progressspinner"
import {eventServiceApi} from "@/api/EventServiceApi"
import AttachmentUpload from "@/util/AttachmentUpload"

@Options({
  components: {
    //@ts-ignore
    InnerLayoutWithSidebar, TaskBoardView, TaskDetails, AnimatedInput, Tree, Avatar, ContextMenu, DavSharingDialog,
    Button, InputText, Dialog, Skeleton, Menu, ColorPicker, SearchBar, TokenAttachmentList, ProgressSpinner
  }
})
export default class TaskView extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()

  menuItems: any[] = []
  taskBoardsAreLoading: boolean = true

  //@ts-ignore
  contextMenu: ContextMenu = ref<ContextMenu | null>(null)
  //@ts-ignore
  menu: Menu = ref<Menu | null>(null)
  //@ts-ignore
  attachmentcontrol: TokenAttachmentList = ref<TokenAttachmentList | null>(null)
  attachments: AttachmentUpload[] = []
  importingICS: boolean = false
  uploadingICS: boolean = false
  uploadingAttachment: AttachmentUpload | null = null

  newBoardLoading = false
  showNewBoardModal = false
  newBoardName = ''

  boardToShare: TaskBoard | null = null
  boardToRename: TaskBoard | null = null
  boardToColorPick: TaskBoard | null = null
  boardToImportTo: string | null = null
  renameLoading: boolean = false
  refresh: number | boolean = 10000

  taskDraft: Task | null = null

  openMobileMenuDefault: boolean = false

  searchQuery: Query | null = null

  get showColorPicker() {
    return !!this.boardToColorPick
  }

  set showColorPicker(show: boolean) {
    if (!show) {
      this.boardToColorPick = null
    }
  }

  get columnNames(): string[] {
    let names: string[] = []
    if (this.taskBoard?.meta?.taskLists) {
      for (let list of this.taskBoard.meta?.taskLists) {
        if (list?.name) {
          names.push(list.name)
        }
      }
    }
    return names
  }

  get boards(): TaskBoard[] {
    const swr: SWR<TaskBoard[], string[]> = taskBoardServiceApi.getTaskBoards(this.refresh)
    this.taskBoardsAreLoading = Boolean(swr.call?.loading && swr.call?.promise)
    if (swr.call?.promise) {
      swr.call.promise.finally(() => {
        this.taskBoardsAreLoading = false
      })
    }
    const collections = swr.data ? [...swr.data] : []
    const orderedCollections: TaskBoard[] = []
    const order: string[] | null | undefined = SettingsUtil.getLastViewedCollectionOrder('taskBoard')
    if (order) {
      for (let id of order) {
        const collectionIndex: number = collections.findIndex(b => b.originalId === id || b.id === id)
        if (collectionIndex >= 0) {
          orderedCollections.push(collections[collectionIndex])
          collections.splice(collectionIndex, 1)
        }
      }
    }
    orderedCollections.push(...collections.sort((a, b) => SortAndFilterUtil.compare(a.name, b.name)))
    if (order) void SettingsUtil.setLastViewedCollectionOrder('taskBoard', orderedCollections.map(b => b.originalId || ''))
    return orderedCollections
  }

  get task(): Task | null {
    return this.taskDraft || (this.taskId ? (taskServiceApi.getTask(this.taskId) || null) : null)
  }

  showBoardContextMenu(board: TaskBoard, event: Event) {
    this.menuItems = this.getBoardMenuItems(board)
    this.menu.hide()
    if (this.menuItems.length > 0) {
      void this.$nextTick(() => {
        this.contextMenu.toggle(event)
      })
    }
  }

  showBoardMenu(board: TaskBoard, event: Event) {
    this.menuItems = this.getBoardMenuItems(board)
    this.contextMenu.hide()
    if (this.menuItems.length > 0) {
      void this.$nextTick(() => {
        this.menu.toggle(event)
      })
    }
  }

  getBoardMenuItems(board: TaskBoard): any[] {
    const menuItems = []
    if (['OWNER'].includes(board.shareAccess || '')) {
      menuItems.push({
        label: this.i18n.$gettext('Share with...'),
        icon: 'cil-share',
        command: () => {
          this.boardToShare = board
        }
      })
    }
    if (['WRITE', 'OWNER'].includes(board.shareAccess || '')) {
      menuItems.push({
        label: this.i18n.$gettext('Import ICS...'),
        icon: 'cil-cloud-upload',
        command: () => {
          this.boardToImportTo = board.originalId
          this.attachmentcontrol.openNativeFileChooser()
        }
      })
    }
    /*menuItems.push({
      label: this.i18n.$gettext('Choose Color'),
      icon:'cil-color-palette',
      command: () => {
        this.boardToColorPick = Object.assign(new TaskBoard(), board)
      }
    })*/
    menuItems.push({
      label: this.i18n.$gettext('Rename Task Board'),
      icon:'cil-pencil',
      command: () => {
        this.boardToRename = Object.assign(new TaskBoard(), board)
      }
    })
    if (!board.isDefault) {
      menuItems.push({
        label: this.i18n.$gettext('Delete'),
        icon: 'cil-trash',
        command: () => {
          this.deleteTaskBoard(board)
        }
      })
    }

    return menuItems
  }

  importICS() {
    const boardId = this.boardToImportTo
    if (this.attachments?.length && boardId && this.attachments[0].promise) {
      const attachment = this.attachments[0]
      this.uploadingICS = true
      this.uploadingAttachment = attachment
      attachment.promise?.then(() => {
        this.uploadingICS = false
        this.uploadingAttachment = null
        this.importingICS = true
        taskServiceApi._importTasks(boardId, attachment.handle).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Tasks could not be imported from .ics file."))
        }).finally(() => {
          this.importingICS = false
        })
      })
      this.boardToImportTo = null
      this.attachments = []
    }
  }

  saveColor() {
    if (this.boardToColorPick) {
      taskBoardServiceApi._updateTaskBoard(this.boardToColorPick).then(() => {
        this.toast.success(this.i18n.$gettext("Task board updated"))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Task board could not be updated"))
      })
      this.boardToColorPick = null
    }
  }

  saveName() {
    if (this.boardToRename) {
      this.renameLoading = true
      taskBoardServiceApi._updateTaskBoard(this.boardToRename).then(() => {
        this.toast.success(this.i18n.$gettext("Task board renamed"))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Task board could not be renamed"))
      }).finally(() => {
        this.renameLoading = false
      })
      this.boardToRename = null
    }
  }

  deleteTaskBoard(board: TaskBoard) {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to delete this task board?'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (board.originalId) {
          taskBoardServiceApi._deleteTaskBoard(board.originalId).then(() => {
            this.toast.success(this.i18n.$gettext("Board deleted"))
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Board could not be deleted"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  taskBoardIsActive(taskBoard: TaskBoard): boolean {
    if (!this.taskBoard || !this.taskBoard.originalId || !taskBoard || !taskBoard.originalId) return false
    return this.taskBoard.originalId === taskBoard.originalId
  }

  newTask() {
    this.taskDraft = new Task()
  }

  goToTaskBoard(board: TaskBoard) {
    if (board.originalId) {
      this.goToTaskBoardId(board.originalId)
    }
  }

  goToTaskBoardId(taskBoardId: string) {
    void this.$router.push('/tasks/' + taskBoardId)
    void SettingsUtil.setLastViewedCollection('taskBoard', taskBoardId)
  }

  openTask(task: Task) {
    void this.$router.push('/tasks/' + task.originalParentId + '/' + task.originalId)
  }

  closeTask(): void {
    this.taskDraft = null
    if (this.$route?.params?.hasOwnProperty("board")) {
      void this.$router.push('/tasks/' + this.$route.params["board"] as string)
    } else {
      void this.$router.push('/tasks')
    }
  }

  get taskBoardId(): string | null {
    return this.$route?.params?.hasOwnProperty("board") ? this.$route.params["board"] as string : null
  }

  get taskBoard(): TaskBoard | null {
    if (this.taskBoardId) {
      const boardId = this.$route.params["board"] as string
      const boards: TaskBoard[] = taskBoardServiceApi.getTaskBoards(this.refresh).data || []
      return boards.find((board: TaskBoard) => {
        return board.originalId == boardId
      }) || null
    } else {
      return null
    }
  }

  get taskBoardName(): string | null {
    return this.taskBoard?.name || null
  }

  get taskId(): string | null {
    if (this.$route?.params?.hasOwnProperty("task")) {
      return this.$route.params["task"] as string
    } else {
      return null
    }
  }

  createTaskBoard(): void {
    let toCreate = new TaskBoard()
    toCreate.name = this.newBoardName
    this.newBoardLoading = true
    void taskBoardServiceApi._createTaskBoard(toCreate).then(() => {
      this.newBoardLoading = false
      this.showNewBoardModal = false
      this.newBoardName = ""
      this.toast.success(this.i18n.$gettext("Taskboard created"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not create the taskboard"))
    }).finally(() => {
      this.newBoardLoading = false
    })
  }

  get hasTasksBooked(): boolean {
    return featureSubset.hasDAV
  }

  get isOnMobile() {
    return breakpointUtil.isOnMobile()
  }

  mounted() {
    if (!this.taskBoardId) {
      let lastViewed: string | null | undefined = SettingsUtil.getLastViewedCollection('taskBoard')
      const taskBoard = (this.boards || []).find(t => t.originalId === lastViewed || t.id === lastViewed)
      if (!this.taskBoardsAreLoading && !taskBoard) {
        //This taskBoard no longer exist.
        lastViewed = undefined
        //Reset settings
        void SettingsUtil.setLastViewedCollection('taskBoard', '')
      }
      if (taskBoard?.originalId) {
        this.goToTaskBoardId(taskBoard.originalId)
      } else {
        if (this.isOnMobile) {
          this.openMobileMenuDefault = true
        } else {
          //Select default board:
          if (this.boards.length > 0) {
            const defaultTaskBoard: string | null = this.boards[0].originalId
            if (defaultTaskBoard) {
              this.goToTaskBoardId(defaultTaskBoard)
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables";

.boards-panel {

  width: 18rem;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
     display: none;
   }

  .board-menu-item {

  }

.board-menu-item:hover {
  background-color: $uniki_secondary;
  cursor: pointer;

& > a {
    text-decoration: underline;
  }

}

.board-menu-item.active {
  background-color: $uniki_primary;
  color: white;
}

}
</style>
