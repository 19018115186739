
import {Options, Vue} from "vue-class-component"
import CalendarEvent from "@/model/entry/Event"
import Calendar from "@/model/directory/Calendar"
import {calendarServiceApi} from "@/api/CalendarServiceApi"
import { ref } from "@vue/reactivity"
import '@fullcalendar/core'
import rrulePlugin from '@fullcalendar/rrule'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from "@fullcalendar/list"
import bootstrapPlugin from "@fullcalendar/bootstrap"
import deLocale from "@fullcalendar/core/locales/de"
import FullCalendar from "primevue/fullcalendar"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import ContextMenu from "primevue/contextmenu"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Avatar from "@/components/common/Avatar.vue"
import Tree from "@/components/common/Tree.vue"
import SWR from "@/api/SWR"
import {eventServiceApi} from "@/api/EventServiceApi"
import {EventApi} from "@fullcalendar/core"
import EventDetails from "@/components/calendar/EventDetails.vue"
import InboxDetails from "@/components/calendar/InboxDetails.vue"
import dayjs, {Dayjs} from "dayjs"
import Dialog from 'primevue/dialog'
import Checkbox from "primevue/checkbox"
import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router"
import {Watch} from "vue-property-decorator"
import Menu from "primevue/menu"
import RpcError from "@/api/RpcError"
import InputText from "primevue/inputtext"
import Button from "primevue/button"
import AutoComplete from "primevue/autocomplete"
import Dropdown from "primevue/dropdown"
import DavSharingDialog from "@/components/common/DavSharingDialog.vue"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import useToast from "@/util/toasts"
import Skeleton from "primevue/skeleton"
import SettingsUtil from "@/util/SettingsUtil"
import {useConfirm} from "primevue/useconfirm"
import DateTimeUtil from "@/util/DateTimeUtil"
import ColorPicker from "@/components/common/ColorPicker.vue"
import featureSubset from "@/util/FeatureSubsets"
import InnerLayoutWithSidebar from "@/components/common/InnerLayoutWithSidebar.vue"
import breakpointUtil from "@/util/BreakpointUtil"
import {eventStore} from "@/store/EventStore"
import Badge from 'primevue/badge'
import {calendarInboxServiceApi} from "@/api/CalendarInboxServiceApi"
import {rpcClient} from "@/api/WebsocketClient"
import Attendee from "@/model/common/caldav/Attendee"
import InputSwitch from "primevue/inputswitch"
import SearchBar from "@/components/common/SearchBar.vue"
import TokenAttachmentList from "@/components/common/TokenAttachmentList.vue"
import ProgressSpinner from "primevue/progressspinner"
import AttachmentUpload from "@/util/AttachmentUpload"

// Only needed if we don't move the entire calendar to UTC
// Monkey patch rrulePlugin for FullCalendar to fix https://github.com/fullcalendar/fullcalendar/issues/5273
// (Recurring events don't respect timezones in FullCalendar)
// We simply replace the expand function here: https://github.com/fullcalendar/fullcalendar/blob/ede23c4b2bf0ee0bb2cbe4694b3e899a09d14da6/packages/rrule/src/main.ts#L36-L56
// With a custom version below
/*rrulePlugin.recurringTypes[0].expand = function (errd, fr, de) {
  const originalDate: Date = errd.rruleSet.dtstart()
  const dates= errd.rruleSet.between(
    fr.start,
    fr.end,
    true, // inclusive (will give extra events at start, see https://github.com/jakubroztocil/rrule/issues/84)
  ).map((date: Date) => {
    return new Date(de.createMarker(date).getTime() + (date.getTimezoneOffset() - originalDate.getTimezoneOffset()) * 60 * 1000)
  })
  return dates
} */

@Options({
  components: {
    //@ts-ignore
    InnerLayoutWithSidebar, FullCalendar, AnimatedInput, Tree, Avatar, EventDetails, ContextMenu, Dialog, Checkbox, Menu,
    InputText, Button, AutoComplete, Dropdown, DavSharingDialog, Skeleton, ColorPicker, Badge, InboxDetails, InputSwitch,
    SearchBar, TokenAttachmentList, ProgressSpinner
  }
})
export default class CalendarView extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()
  route: RouteLocationNormalizedLoaded = useRoute()
  router: Router = useRouter()
  eventStore = eventStore

  newCalendarLoading = false

  calendarsAreLoading: boolean = false
  menuItems: any[] = []
  viewRangeMillis: number = 3628800000
  start: Date = new Date( new Date().getTime() - this.viewRangeMillis)
  end: Date = new Date(new Date().getTime() + this.viewRangeMillis)
  lastRefreshedRange: number[] | null = null
  selectedCalendars: string[] = []

  //@ts-ignore
  fullCalendar: FullCalendar = ref<FullCalendar | null>(null)
  //@ts-ignore
  contextMenu: ContextMenu = ref<ContextMenu | null>(null)
  //@ts-ignore
  menu: Menu = ref<Menu | null>(null)
  //@ts-ignore
  inboxDetails: InboxDetails = ref<InboxDetails | null>(null)
  //@ts-ignore
  attachmentcontrol: TokenAttachmentList = ref<TokenAttachmentList | null>(null)
  attachments: AttachmentUpload[] = []
  importingICS: boolean = false
  uploadingICS: boolean = false
  uploadingAttachment: AttachmentUpload | null = null

  showNewCalendarModal = false
  newCalendarName = ''

  calendarToShare: Calendar | null = null
  calendarToRename: Calendar | null = null
  calendarToColorPick: Calendar | null = null
  calendarToImportTo: string | null = null
  renameLoading: boolean = false

  eventDraft: CalendarEvent | null = null
  modifiedOrNewEvent: any | null = null
  recurringEventToDelete: EventApi | null = null
  recurringSeriesExceptionToDelete: EventApi | null = null
  oldEvent: any | null = null

  openMobileMenuDefault: boolean = false
  calendarLastSetView: string = SettingsUtil.getLastSetCalendarView()
  isListView: boolean = SettingsUtil.getLastSetCalendarView()?.includes('list') || false

  openNewCalendarModal(): void {
    this.newCalendarName = ''
    this.showNewCalendarModal = true
  }

  get showColorPicker() {
    return !!this.calendarToColorPick
  }

  set showColorPicker(show: boolean) {
    if (!show) {
      this.calendarToColorPick = null
    }
  }

  toggleShowNotifications(): void {
    this.inboxDetails.toggle()
  }

  get inboxCount(): number {
    return calendarInboxServiceApi.readInbox().data?.length || 0
  }

  get calendars(): Calendar[] {
    const swr: SWR<Calendar[], string[]> = calendarServiceApi.getCalendars()
    this.calendarsAreLoading = Boolean(swr.call?.loading && swr.call?.promise)
    if (swr.call?.promise){
      swr.call.promise.finally(() => {
        this.calendarsAreLoading = false
      })
    }
    const collections = swr.data ? [...swr.data] : []
    const orderedCollections: Calendar[] = []
    const order: string[] | null | undefined = SettingsUtil.getLastViewedCollectionOrder('calendar')
    if (order) {
      for (let id of order) {
        const collectionIndex: number = collections.findIndex(b => b.originalId === id || b.id === id)
        if (collectionIndex >= 0) {
          orderedCollections.push(collections[collectionIndex])
          collections.splice(collectionIndex, 1)
        }
      }
    }
    orderedCollections.push(...collections.sort((a, b) => SortAndFilterUtil.compare(a.name, b.name)))
    if (order) void SettingsUtil.setLastViewedCollectionOrder('calendar', orderedCollections.map(b => b.originalId || ''))
    return orderedCollections
  }

  get events(): { id: string | null, title: string | null, start: any, extendedProps: any }[] | null {
    const allEvents: any[] = []
    const promises: Map<string, Promise<string[]>> = new Map<string, Promise<string[]>>()
    const returnedIds: string[] = []
    const start = this.start.toISOString()
    const end = this.end.toISOString()
    const selectedCalendars = [...this.selectedCalendars]
    //Make sure the local state contains enough recurrence instances
    const refresh: number | boolean = Boolean(
      !this.lastRefreshedRange ||
        this.lastRefreshedRange[0] > this.start.getTime() ||
        this.lastRefreshedRange[1] < this.end.getTime())
    for (const calendarId of selectedCalendars) {
      if (calendarId) try {
        const eventSWR: SWR<CalendarEvent[], string[]> = eventServiceApi.getEvents(calendarId, this.start.toISOString(), this.end.toISOString(), refresh)
        if ((eventSWR.call?.loading || eventSWR.call?.refreshing) &&
          (!this.lastRefreshedRange || this.lastRefreshedRange[0] != this.start.getTime() || this.lastRefreshedRange[1] != this.end.getTime())) {
          this.lastRefreshedRange = [ this.start.getTime(), this.end.getTime() ]
        }
        if (eventSWR.call?.promise) {
          promises.set(calendarId, eventSWR.call.promise)
          void eventSWR.call.promise.then((ids: string[]) => {
            returnedIds.push(...ids)
          })
        }
      } catch (error) {}
    }
    if (promises.size) {
      const calendarIds: string[] = [...promises.keys()]
      Promise.all(promises.values()).finally(() => {
        //Clean up the local state: If a cached event is in this date range, but was not returned, it must have been deleted
        [...eventStore.state.events].forEach(([id, event]) => {
          if (event.originalParentId && calendarIds.includes(event.originalParentId) &&
            !returnedIds.includes(event.originalId || '') && !!event.instanceStart &&
            SortAndFilterUtil.compare(event.instanceStart, start) >= 0 &&
            SortAndFilterUtil.compare(event.instanceStart, end) <= 0) {
            this.eventStore.removeEvent(event.originalId)
          }
        })
      })
    }
    const filterMyEvents = this.filterMyEvents
    let userEmail: string = rpcClient.session.user?.email ?? ""
    const eventsByCalendar: Map<string, CalendarEvent[]> = new Map<string, CalendarEvent[]>()
    this.eventStore.state.events.forEach((event: CalendarEvent) => {
      if (event.originalParentId) {
        let includeEvent: boolean = true
        if (filterMyEvents && userEmail != undefined && userEmail !== "") {
          if (!(event.organizer?.email == userEmail)) { //not organizer
            if (!event.attendees || !event.attendees.find((att) => {
              return (att.email == userEmail && (att.status != 'DECLINED' && att.status != 'DELEGATED')) })) {
              includeEvent = false
            }
          }
        }
        if (includeEvent) {
          let eventsInCalendar: CalendarEvent[] | undefined = eventsByCalendar.get(event.originalParentId)
          if (!eventsInCalendar) {
            eventsInCalendar = []
            eventsByCalendar.set(event.originalParentId, eventsInCalendar)
          }
          eventsInCalendar.push(event)
        }
      }
    })
    eventsByCalendar.forEach((events: CalendarEvent[], calendarId: string) => {
      if (selectedCalendars.includes(calendarId)) {
        const calendar: Calendar | undefined = this.calendars ? this.calendars.find((c: Calendar) => c.originalId === calendarId) : undefined
        events.forEach((event: CalendarEvent) => {
          const attendee: Attendee | undefined = event.attendees?.find(a => a.email === rpcClient.session?.user?.email)
          const fcEvent: any = {
            id: event.originalId,
            title: event.summary,
            allDay: event.allDay,
            extendedProps: {
              calendarId: event.originalParentId,
              isCancelled: event.status === 'CANCELLED',
              isInvite: !!attendee,
              needsResponse: attendee?.status === 'NEEDS_ACTION',
              isDeclined: attendee?.status === 'DECLINED'
            },
            borderColor: calendar?.colorHex,
            color: event.color ? event.color : calendar?.colorHex
          }

          //TODO Also check recurrenceDates
          if (event.instanceDates?.dates) {
            //TODO Also check exceptionRules
            const exceptionTimes: number[] = DateTimeUtil.getExceptionTimes(event, events)
            for (let date of event.instanceDates.dates) {
              const time: number = Date.parse(date)
              if (!exceptionTimes.includes(time)) {
                const instance = Object.assign({}, fcEvent)
                instance.id = event.originalId + '#' + time
                instance.start = new Date(time - new Date(time).getTimezoneOffset() * 60000)
                instance.end = new Date(instance.start.getTime() + DateTimeUtil.getDurationInMillis(event))
                allEvents.push(instance)
              }
            }
          } else if (event.recurrenceRule) {
            fcEvent.duration = DateTimeUtil.getDurationInMillis(event) //Required when rrule is set
            fcEvent.exdate = DateTimeUtil.getExceptionDates(event, events)
            fcEvent.exrule = DateTimeUtil.getExceptionRules(event, events)
            fcEvent.rrule = DateTimeUtil.getRrule(event, event.recurrenceRule) //This does set the start in the rrule
            allEvents.push(fcEvent)
          } else if (event.start) {
            const start: number = Date.parse(event.start)
            fcEvent.start = new Date(start - new Date(start).getTimezoneOffset() * 60000)
            if (event.end) {
              const end: number = Date.parse(event.end)
              fcEvent.end = new Date(end - new Date(end).getTimezoneOffset() * 60000)
              allEvents.push(fcEvent)
            } else if (event.duration) {
              fcEvent.end = fcEvent.start + DateTimeUtil.getMillisFromDuration(event.duration)
              allEvents.push(fcEvent)
            }
          }
        })
      }
    })
    return allEvents
  }

  get event(): CalendarEvent | null {
    return this.eventDraft || (this.eventId ? this.eventStore.state.events.get(this.eventId) : null) || null
  }

  showNewEventContextMenu(event: Event) {
    if (this.menu) {
      this.menuItems = [
        {
          label: this.i18n.$gettext('New Event'),
          icon:'fa fa-folder',
          'class': 'newEventMenuItem',
          command: () => {
            this.newEvent()
          }
        }
      ]
      void this.$nextTick(() => {
        this.contextMenu.show(event)
      })
    }
  }

  showEventContextMenu(item: EventApi, event: Event) {
    const calendar: Calendar | undefined = calendarServiceApi.getCalendar(item.extendedProps.calendarId)
    const shareAccess = calendar?.shareAccess || 'READ'

    const originalId: string | null = this.getOriginalIdFromRecurrenceInstanceId(item.id)
    if (originalId && this.menu) {
      const items: any[] = []

      if (['WRITE', 'OWNER'].includes(shareAccess)) {
        /*
        TODO create duplicate command
        items.push({
          label: this.i18n.$gettext('Duplicate'),
          icon:'cil-copy',
          command: () => {
            const originalEvent: SWR<CalendarEvent | null, string> = eventServiceApi.getEvent(originalId, false)
            const promise: Promise<any> = originalEvent.call?.promise || Promise.resolve()
            promise.finally(() => {


              const copy: CalendarEvent = Object.assign(new CalendarEvent(), event)
              this.deleteEvent(item)
            })
          }
        })*/
        items.push({
          label: this.i18n.$gettext('Delete'),
          icon:'cil-trash',
          command: () => {
            this.deleteEvent(item)
          }
        })

        const writable = ['WRITE', 'OWNER']
        const calendars: Calendar[] = (calendarServiceApi.getCalendars().data || []).filter(c => !c.shareAccess || writable.includes(c.shareAccess))
        if (calendars && calendars.length > 1) {
          items.push({
            label: this.i18n.$gettext('Move to...'),
            icon: '',
            items: calendars.map((calendar: Calendar) => {
              return {
                label: calendar.name,
                icon:'cil-calendar',
                command: () => {
                  const originalEvent: SWR<CalendarEvent | null, string> = eventServiceApi.getEvent(originalId, false)
                  const promise: Promise<any> = originalEvent.call?.promise || Promise.resolve()
                  promise.finally(() => {
                    if (originalEvent.data && calendar.originalId != null) {
                      originalEvent.data.originalParentId = item.extendedProps.calendarId
                      eventServiceApi._moveEvent(originalEvent.data, calendar.originalId).then(() => {
                        this.toast.success(this.i18n.$gettext("Event moved"))
                      }).catch((e: RpcError) => {
                        this.toast.error(e.message, this.i18n.$gettext("Event could not be moved"))
                      })
                    }
                  })
                }
              }
            })
          })
        }
      }

      if (items.length > 0) {
        this.menuItems = items
        void this.$nextTick(() => {
          this.contextMenu.show(event)
        })
      }
    } else if (['WRITE', 'OWNER'].includes(shareAccess)) {
      this.showNewEventContextMenu(event)
    }
  }

  showCalendarContextMenu(calendar: Calendar, event: Event) {
    this.menuItems = this.getCalendarMenuItems(calendar)
    this.menu.hide()
    if (this.menuItems.length > 0) {
      void this.$nextTick(() => {
        this.contextMenu.toggle(event)
      })
    }
  }

  showCalendarMenu(calendar: Calendar, event: Event) {
    this.menuItems = this.getCalendarMenuItems(calendar)
    this.contextMenu.hide()
    if (this.menuItems.length > 0) {
      void this.$nextTick(() => {
        this.menu.toggle(event)
      })
    }
  }

  getCalendarMenuItems(calendar: Calendar): any[] {
    const menuItems = []
    if (['OWNER'].includes(calendar.shareAccess || '')) {
      menuItems.push({
        label: this.i18n.$gettext('Share with...'),
        icon: 'cil-share',
        command: () => {
          this.calendarToShare = calendar
        }
      })
      menuItems.push({
        label: this.i18n.$gettext('Rename Calendar'),
        icon:'cil-pencil',
        command: () => {
          this.calendarToRename = Object.assign(new Calendar(), calendar)
        }
      })
      menuItems.push({
        label: this.i18n.$gettext('Set as default for invitations'),
        icon:'cil-calendar-check',
        command: () => {
          void calendarServiceApi._changeScheduleDefaultCalendar(calendar.originalId).then(() => {
            this.toast.success(this.i18n.$gettext("Default calendar for invitations was set."))
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Failed to set default calendar for invitations."))
          })
        }
      })
    }
    if (['WRITE', 'OWNER'].includes(calendar.shareAccess || '')) {
      menuItems.push({
        label: this.i18n.$gettext('Import ICS...'),
        icon: 'cil-cloud-upload',
        command: () => {
          this.calendarToImportTo = calendar.originalId
          this.attachmentcontrol.openNativeFileChooser()
        }
      })
    }
    menuItems.push({
      label: this.i18n.$gettext('Choose Color'),
      icon:'cil-color-palette',
      command: () => {
        this.openColorPicker(calendar)
      }
    })
    if (!calendar.isDefault) {
      menuItems.push({
        label: this.i18n.$gettext('Delete'),
        icon: 'cil-trash',
        command: () => {
          this.deleteCalendar(calendar)
        }
      })
    }

    return menuItems
  }

  openColorPicker(calendar: Calendar){
    this.calendarToColorPick = Object.assign(new Calendar(), calendar)
  }

  deleteEvent(item: EventApi, deleteInstance?: boolean | undefined) {
    const originalId: string = this.getOriginalIdFromRecurrenceInstanceId(item.id) || ''
    const originalEvent: SWR<CalendarEvent | null, string> = eventServiceApi.getEvent(originalId, false)
    const promise: Promise<any> = originalEvent.call?.promise || Promise.resolve()
    promise.finally(() => {
      if (originalEvent.data) {
        if (originalEvent.data.recurrenceRule && deleteInstance === undefined) {
          this.recurringEventToDelete = item
        } else if (originalEvent.data.recurrenceId && deleteInstance === undefined &&
          !this.recurringSeriesExceptionToDelete && this.findMasterEvent(originalEvent.data)) {
          this.recurringSeriesExceptionToDelete = item
        } else if (this.recurringSeriesExceptionToDelete && deleteInstance === undefined) {
          //Restore the original event by removing the exception
          eventServiceApi._deleteEvent(originalId).then(() => {
            this.toast.success(this.i18n.$gettext("Event instance deleted"))
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Event instance could not be deleted"))
          }).finally(() => {
            this.deletingRecurringSeriesException = false
          })
        } else if (deleteInstance === undefined) {
          this.confirm.require({
            message: this.i18n.$gettext('Do you really want to delete this event?'),
            header: this.i18n.$gettext('Confirmation'),
            icon: 'cil-warning',
            accept: () => {
              if (originalId) {
                eventServiceApi._deleteEvent(originalId).then(() => {
                  this.toast.success(this.i18n.$gettext("Event deleted"))
                }).catch((e: RpcError) => {
                  this.toast.error(e.message, this.i18n.$gettext("Event could not be deleted"))
                })
              }
            },
            reject: () => {
              //callback to execute when user rejects the action
            }
          })
        } else if (deleteInstance && this.recurringSeriesExceptionToDelete) {
          //Create an exception in the series
          const masterEvent: CalendarEvent | undefined = this.findMasterEvent(originalEvent.data)
          const recurrenceIdStart = originalEvent.data.recurrenceId?.start
          let modifyMasterEventPromise: Promise<any> = Promise.resolve()
          if (masterEvent && recurrenceIdStart) {
            masterEvent.exceptionDates = masterEvent.exceptionDates || []
            masterEvent.exceptionDates.push(recurrenceIdStart)
            modifyMasterEventPromise = eventServiceApi._updateEvent(masterEvent)
          }
          modifyMasterEventPromise.finally(() => {
            //In addition to creating the exception in the series, remove the exception event
            eventServiceApi._deleteEvent(originalId).then(() => {
              this.toast.success(this.i18n.$gettext("Event deleted"))
            }).catch((e: RpcError) => {
              this.toast.error(e.message, this.i18n.$gettext("Event could not be deleted"))
            }).finally(() => {
              this.deletingRecurringSeriesException = false
            })
          })
        } else if (deleteInstance && originalEvent.data && item.start) {
          originalEvent.data.exceptionDates = originalEvent.data.exceptionDates || []
          const exceptionDates: Date = new Date(item.start.getTime() + item.start.getTimezoneOffset() * 60000)
          originalEvent.data.exceptionDates.push(exceptionDates.toISOString())
          eventServiceApi._updateEvent(originalEvent.data).then(() => {
            this.toast.success(this.i18n.$gettext("Event instance deleted"))
            this.removeExceptionEvents(originalEvent?.data?.uid)
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Event instance could not be deleted"))
          }).finally(() => {
            this.recurringEventToDelete = null
            this.deletingRecurringEvent = false
          })
        } else if (!deleteInstance && this.recurringSeriesExceptionToDelete) {
          const masterEvent: CalendarEvent | undefined = this.findMasterEvent(originalEvent.data)
          if (masterEvent?.originalId) {
            eventServiceApi._deleteEvent(masterEvent.originalId).then(() => {
              this.toast.success(this.i18n.$gettext("Event series deleted"))
              this.removeExceptionEvents(originalEvent?.data?.uid)
            }).catch((e: RpcError) => {
              this.toast.error(e.message, this.i18n.$gettext("Event series could not be deleted"))
            }).finally(() => {
              this.deletingRecurringSeriesException = false
            })
          } else {
            this.toast.error(this.i18n.$gettext("Event series could not be deleted"))
          }
        } else if (!deleteInstance && originalId) {
          eventServiceApi._deleteEvent(originalId).then(() => {
            this.toast.success(this.i18n.$gettext("Event deleted"))
            this.removeExceptionEvents(originalEvent?.data?.uid)
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Event could not be deleted"))
          }).finally(() => {
            this.deletingRecurringEvent = false
          })
        }
      }
    })
  }

  //TODO Get the master event from the server
  findMasterEvent(exceptionInstance: CalendarEvent): CalendarEvent | undefined {
    return [...this.eventStore.state.events.values()].find(e => {
      return e.uid === exceptionInstance.uid && ! e.recurrenceId
    })
  }

  removeExceptionEvents(uid: string | null | undefined) {
    if (uid) {
      [...eventStore.state.events].forEach(([id, event]) => {
        if (event.uid == uid) {
          this.eventStore.removeEvent(event.originalId)
        }
      })
    }
  }

  importICS() {
    const calendarId = this.calendarToImportTo
    if (this.attachments?.length && calendarId && this.attachments[0].promise) {
      const attachment = this.attachments[0]
      this.uploadingICS = true
      this.uploadingAttachment = attachment
      attachment.promise?.then(() => {
        this.uploadingICS = false
        this.uploadingAttachment = null
        this.importingICS = true
        eventServiceApi._importEvents(calendarId, attachment.handle).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Events could not be imported from .ics file."))
        }).finally(() => {
          this.importingICS = false
        })
      })
      this.calendarToImportTo = null
      this.attachments = []
    }
  }

  saveColor() {
    if (this.calendarToColorPick) {
      calendarServiceApi._updateCalendar(this.calendarToColorPick).then(() => {
        this.toast.success(this.i18n.$gettext("Calendar updated"))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Calendar could not be updated"))
      })
      this.calendarToColorPick = null
    }
  }

  saveName() {
    if (this.calendarToRename) {
      this.renameLoading = true
      calendarServiceApi._updateCalendar(this.calendarToRename).then(() => {
        this.toast.success(this.i18n.$gettext("Calendar renamed"))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Calendar could not be renamed"))
      }).finally(() => {
        this.renameLoading = false
      })
      this.calendarToRename = null
    }
  }

  deleteCalendar(calendar: Calendar) {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to delete this calendar?'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (calendar.originalId) {
          calendarServiceApi._deleteCalendar(calendar.originalId).then(() => {
            this.toast.success(this.i18n.$gettext("Calendar deleted"))
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Calendar could not be deleted"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  newEvent() {
    this.eventDraft = this.createOrUpdateEventFromEventApi(this.modifiedOrNewEvent)
  }

  modifyEvent(event: EventApi, oldEvent?: EventApi) {
    const originalId: string = this.getOriginalIdFromRecurrenceInstanceId(event.id) || ''
    const originalEvent: SWR<CalendarEvent | null, string> = eventServiceApi.getEvent(originalId, false)
    const promise: Promise<any> = originalEvent.call?.promise || Promise.resolve()
    promise.finally(() => {
      if (originalEvent.data && oldEvent && originalEvent.data.recurrenceRule && DateTimeUtil.isSameDay(event.start, oldEvent.start)) {
        this.modifiedOrNewEvent = event
        this.oldEvent = oldEvent
      } else if (originalEvent.data && oldEvent && originalEvent.data.recurrenceRule) {
        this.modifyInstance(event, oldEvent)
      } else if (originalEvent.data && event.start) {
        const modifiedEvent: CalendarEvent = this.createOrUpdateEventFromEventApi(event, originalEvent.data)
        eventServiceApi._updateEvent(modifiedEvent).then(() => {
          this.toast.success(this.i18n.$gettext("Event updated"))
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Event could not be updated"))
        }).finally(() => {
          this.modifiedOrNewEvent = this.oldEvent = null
        })
      }
    })
  }

  modifyInstance(event: EventApi, oldEvent: EventApi) {
    const originalId: string = this.getOriginalIdFromRecurrenceInstanceId(event.id) || ''
    const originalEvent: SWR<CalendarEvent | null, string> = eventServiceApi.getEvent(originalId, false)
    const promise: Promise<any> = originalEvent.call?.promise || Promise.resolve()
    promise.finally(() => {
      if (originalEvent.data && event.start) {
        const modifiedEvent: CalendarEvent = Object.assign(new CalendarEvent(), originalEvent.data)
        modifiedEvent.instanceDates = null
        modifiedEvent.recurrenceRule = null
        modifiedEvent.recurrenceId = {
          start: oldEvent.start ? new Date(oldEvent.start.getTime() + oldEvent.start.getTimezoneOffset() * 60000).toISOString() : null,
          range: null
        }
        modifiedEvent.start = new Date(event.start.getTime() + event.start.getTimezoneOffset() * 60000).toISOString() //Shift date back from view layer in UTC
        modifiedEvent.duration = DateTimeUtil.getDuration(event.end ? event.end.getTime() - event.start.getTime() : 3600000)
        modifiedEvent.end = null
        modifiedEvent.allDay = event.allDay
        eventServiceApi._addEvent(modifiedEvent).then(() => {
          this.toast.success(this.i18n.$gettext("Event instance updated"))
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Event instance could not be updated"))
        }).finally(() => {
          this.modifiedOrNewEvent = this.oldEvent = null
        })
      }
    })
  }

  createOrUpdateEventFromEventApi(eventApi: EventApi | null, originalEvent?: CalendarEvent): CalendarEvent {
    const modifiedEvent: CalendarEvent = originalEvent ? Object.assign(new CalendarEvent(), originalEvent) : new CalendarEvent()
    if (eventApi && eventApi.start && modifiedEvent.start && modifiedEvent.recurrenceRule) {
      //Do not modify the start day, because it marks the start of the series, NOT the start of this particular instance
      const originalStart: Dayjs = dayjs(modifiedEvent.start)
      let newStart: Date = new Date()
      newStart.setFullYear(originalStart.year(), originalStart.month(), originalStart.date())
      newStart.setHours(eventApi.start.getHours(), eventApi.start.getMinutes(), eventApi.start.getSeconds(), eventApi.start.getMilliseconds())
      newStart = new Date(newStart.getTime() + newStart.getTimezoneOffset() * 60000) //Shift date back from view layer in UTC
      modifiedEvent.start = newStart.toISOString()
      if (eventApi.end) {
        modifiedEvent.duration = DateTimeUtil.getDuration(eventApi.end.getTime() - eventApi.start.getTime()) //Recurring event must have duration
        modifiedEvent.end = null //Recurring event should not have an end
        modifiedEvent.allDay = eventApi.allDay
      } else {
        modifiedEvent.allDay = true
      }
    } else if (eventApi && eventApi.start) {
      modifiedEvent.start = new Date(eventApi.start.getTime() + eventApi.start.getTimezoneOffset() * 60000).toISOString() //Shift date back from view layer in UTC
      let newEnd: Date = eventApi.end || eventApi.start
      if (!eventApi.end) {
        newEnd = new Date(newEnd.getTime() + (eventApi.allDay ? 86400000 : 3600000))
      }
      modifiedEvent.end = new Date(newEnd.getTime() + newEnd.getTimezoneOffset() * 60000).toISOString() //Shift date back from view layer in UTC
      modifiedEvent.allDay = eventApi.allDay
    } else {
      let offset = (new Date().getTimezoneOffset() / 60) //Shift date back from view layer in UTC
      modifiedEvent.start = dayjs().hour(8 + offset).minute(0).second(0).millisecond(0).toISOString()
      modifiedEvent.end = dayjs().hour(9 + offset).minute(0).second(0).millisecond(0).toISOString()
      modifiedEvent.timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    return modifiedEvent
  }

  toggleCalendar(calendar: Calendar, event: any) {
    if (calendar.originalId && (!this.calendarId || !event.target || !event.target['className'] || !event.target.className.includes('checkbox'))) {
      if (this.selectedCalendars.includes(calendar.originalId)) {
        this.selectedCalendars.splice(this.selectedCalendars.indexOf(calendar.originalId), 1)
      } else {
        this.selectedCalendars.push(calendar.originalId)
        this.goToCalendarId(calendar.originalId)
      }
      this.saveSelectedCalendars()
    }
  }

  goToCalendarId(calendarId: string) {
    void this.router.push('/calendar/' + calendarId)
  }

  openEvent(event: EventApi) {
    const originalId: string = this.getOriginalIdFromRecurrenceInstanceId(event.id) || ''
    const originalEvent: SWR<CalendarEvent | null, string> = eventServiceApi.getEvent(originalId, false)
    const promise: Promise<any> = originalEvent.call?.promise || Promise.resolve()
    promise.finally(() => {
      if ((!originalEvent.data?.start || !DateTimeUtil.isSameDay(new Date(originalEvent.data.start), event.start)) && event.start) {
        //User clicked on a virtual recurrence instance => create a new virtual event from the instance, don't change route
        const modifiedEvent: CalendarEvent = Object.assign(new CalendarEvent(), originalEvent.data)
        modifiedEvent.start = new Date(event.start.getTime() + event.start.getTimezoneOffset() * 60000).toISOString() //Shift date back from view layer in UTC
        if (event.end) {
          modifiedEvent.end = new Date(event.end.getTime() + event.end.getTimezoneOffset() * 60000).toISOString() //Shift date back from view layer in UTC
        } else {
          modifiedEvent.end = dayjs(event.start).add(1, 'hour').add(event.start.getTimezoneOffset(), 'minutes').toISOString() //Shift date back from view layer in UTC
        }
        modifiedEvent.allDay = event.allDay
        this.eventDraft = modifiedEvent
      } else {
        void this.router.push('/calendar/' + event.extendedProps.calendarId + '/' + originalId)
      }
    })
  }

  closeEvent(): void {
    this.eventDraft = null
    void this.router.push('/calendar/' + (this.calendarId || ''))
  }

  get deletingRecurringSeriesException(): boolean {
    return !!this.recurringSeriesExceptionToDelete
  }

  set deletingRecurringSeriesException(show: boolean) {
    if (!show) {
      this.recurringSeriesExceptionToDelete = null
    }
  }

  get deletingRecurringEvent(): boolean {
    return !!this.recurringEventToDelete
  }

  set deletingRecurringEvent(show: boolean) {
    if (!show) {
      this.recurringEventToDelete = null
    }
  }

  get showModifyRecurrentEventDialog(): boolean {
    return Boolean(this.modifiedOrNewEvent && this.oldEvent)
  }

  set showModifyRecurrentEventDialog(show: boolean) {
    if (!show) {
      this.modifiedOrNewEvent = null
      this.oldEvent = null
    }
  }

  get calendarId(): string | null {
    if (this.route?.params?.hasOwnProperty("calendar")) {
      return this.route.params["calendar"] as string
    } else {
      return null
    }
  }

  get eventId(): string | null {
    if (this.route?.params?.hasOwnProperty("event")) {
      return this.route.params["event"] as string
    } else {
      return null
    }
  }

  getOriginalIdFromRecurrenceInstanceId(id: string | null | undefined): string | null {
    return id ? id.split('#')[0] : null
  }

  filterMyEvents: boolean = false

  toggleMonthView(): void {
    const view: string = this.isListView ? 'listMonth' : 'dayGridMonth'
    //@ts-ignore
    this.fullCalendar?.calendar?.changeView(view)
    this.setActiveButtonState(view)
  }

  toggleWeekView(): void {
    const view: string = this.isListView ? 'listWeek' : 'timeGridWeek'
    //@ts-ignore
    this.fullCalendar?.calendar?.changeView(view)
    this.setActiveButtonState(view)
  }

  toggleDayView(): void {
    const view: string = this.isListView ? 'listDay' : 'timeGridDay'
    //@ts-ignore
    this.fullCalendar?.calendar?.changeView(view)
    this.setActiveButtonState(view)
  }

  activeClass: string = 'fc-button-active'
  monthButtonClass: string = ".fc-monthButton-button"
  weekButtonClass: string = ".fc-weekButton-button"
  dayButtonClass: string = ".fc-dayButton-button"
  listButtonClass: string = ".fc-listButton-button"
  gridButtonClass: string = ".fc-gridButton-button"
  monthButton: HTMLElement | null = null
  weekButton: HTMLElement | null = null
  dayButton: HTMLElement | null = null
  listButton: HTMLElement | null = null
  gridButton: HTMLElement | null = null

  initCalendarHeaderButtons(): void {
    this.monthButton = this.$el.querySelector(this.monthButtonClass)
    this.weekButton = this.$el.querySelector(this.weekButtonClass)
    this.dayButton = this.$el.querySelector(this.dayButtonClass)
    this.listButton = this.$el.querySelector(this.listButtonClass)
    this.gridButton = this.$el.querySelector(this.gridButtonClass)

    const spacerButtonClass: string = '.fc-spacerButton-button'
    const spacerButton: HTMLElement = this.$el.querySelector(spacerButtonClass)
    if (spacerButton) {
      spacerButton.style.opacity = "0.0"
    }
    //@ts-ignore
    this.setActiveButtonState(this.fullCalendar?.calendar?.view?.type)
  }

  setActiveButtonState(view: string | undefined): void {
    this.monthButton?.classList?.remove(this.activeClass)
    this.weekButton?.classList?.remove(this.activeClass)
    this.dayButton?.classList?.remove(this.activeClass)
    this.listButton?.classList?.remove(this.activeClass)
    this.gridButton?.classList?.remove(this.activeClass)

    if (view?.toLowerCase()?.includes('month')) {
      this.monthButton?.classList?.add(this.activeClass)
      this.viewRangeMillis = 3628800000
    } else if (view?.toLowerCase()?.includes('week')) {
      this.weekButton?.classList?.add(this.activeClass)
      this.viewRangeMillis = 604800000
    } else if (view?.toLowerCase()?.includes('day')) {
      this.dayButton?.classList?.add(this.activeClass)
      this.viewRangeMillis = 86400000
    }
    if (this.isListView) this.listButton?.classList?.add(this.activeClass)
    else this.gridButton?.classList?.add(this.activeClass)

  }

  setListState(isList: boolean): void {
    //@ts-ignore
    const view: string | undefined = this.fullCalendar?.calendar?.view?.type
    this.isListView = isList
    if (view?.includes('Month')) this.toggleMonthView()
    else if (view?.includes('Week')) this.toggleWeekView()
    else if (view?.includes('Day')) this.toggleDayView()
  }

  get largeOptions(): any {
    let now: Date = new Date()
    now = new Date(now.getTime() - now.getTimezoneOffset() * 60 * 1000)
    return {
      //@ts-ignore
      plugins: [ rrulePlugin, listPlugin, bootstrapPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin ],
      initialDate : now,
      timeZone: 'UTC',
      initialView : this.calendarLastSetView,
      customButtons: {
        monthButton: {
          text: this.i18n.$gettext("Month"),
          click: () => {
            this.toggleMonthView()
          }
        },
        weekButton: {
          text: this.i18n.$gettext("Week"),
          click: () => {
            this.toggleWeekView()
          }
        },
        dayButton: {
          text: this.i18n.$gettext("Day"),
          click: () => {
            this.toggleDayView()
          }
        },
        spacerButton: {
          text: ''
        },
        listButton: {
          //icon: 'view-list',
          text: this.i18n.$gettext("List"),
          hint: this.i18n.$gettext("List View"),
          click: () => {
            this.setListState(true)
          }
        },
        gridButton: {
          //icon: 'fa-times',
          text: this.i18n.$gettext("Grid"),
          hint: this.i18n.$gettext("Grid View"),
          click: () => {
            this.setListState(false)
          }
        }
      },
      headerToolbar: {
        left: 'prev,next,today',
        center: 'title',
        right: 'monthButton,weekButton,dayButton,spacerButton,listButton,gridButton'
      },
      eventClick: (event: any) => { this.openEvent(event.event) },
      eventDrop: (event: any) => { this.modifyEvent(event.event, event.oldEvent) },
      eventResize: (event: any) => { this.modifyEvent(event.event, event.oldEvent) },
      select: (event: any) => { this.modifiedOrNewEvent = event },
      unselect: (event: any) => { this.modifiedOrNewEvent = null },
      loading: (event: any) => {  },
      eventPositioned: (event: any) => { console.log('eventPositioned'); console.dir(event) },
      _eventsPositioned: (event: any) => { console.log('_eventsPositioned'); console.dir(event) },
      eventDragStart: (event: any) => { console.log('eventDragStart'); console.dir(event) },
      eventDragStop: (event: any) => { console.log('eventDragStop'); console.dir(event) },
      eventResizeStart: (event: any) => { console.log('eventResizeStart'); console.dir(event) },
      eventResizeStop: (event: any) => { console.log('eventResizeStop'); console.dir(event) },
      drop: (event: any) => { console.log('drop'); console.dir(event) },
      eventReceive: (event: any) => { console.log('eventReceive'); console.dir(event) },
      eventLeave: (event: any) => { console.log('eventLeave'); console.dir(event) },
      _destroyed: (event: any) => { console.log('_destroyed'); console.dir(event) },
      moreLinkClick: (event: any) => {
        setTimeout(() => {
          this.movePopoverIntoWindow()
        }, 1)
        return 'popover'
      },
      eventDidMount: (event: any) => {
        event.el.addEventListener('contextmenu', (e: Event) => {
          this.showEventContextMenu(event.event, e)
        })
      },
      eventClassNames: function(arg: any) {
        const classes: string[] = []
        if (arg.event.extendedProps.isCancelled || arg.event.extendedProps.isDeclined) {
          classes.push('strike-through')
        }
        if (arg.event.extendedProps.needsResponse || arg.event.extendedProps.isDeclined) {
          classes.push('opacity-50')
        }
        return classes
      },
      viewClassNames: (arg: any) => { SettingsUtil.setLastSetCalendarView(arg.view.type) },
      datesSet: (info: { start: Date, end: Date }) => {
        this.start = new Date(info.start.getTime() - this.viewRangeMillis)
        this.end = new Date(info.end.getTime() + this.viewRangeMillis)
      },
      views: {
        timeGridWeek: {
          dayHeaderFormat: this.isOnMobile ? {
            weekday: 'short', day: 'numeric', omitCommas: true
          } : {
            weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true
          } ,
        }
      },
      editable: true,
      selectable:true,
      //selectMirror: true,
      dayMaxEvents: true,
      now: now.toISOString(),
      nowIndicator: true,
      weekNumbers: true,
      //themeSystem: 'bootstrap',
      locale: deLocale,
      unselectCancel: '#newEventButton, .newEventMenuItem',
      schedulerLicenseKey: '0396888875-fcs-1629466440'
    }
  }

  movePopoverIntoWindow(t: number = 0) {
    const popovers: HTMLCollection = document.getElementsByClassName('fc-more-popover')
    if (popovers.length) {
      for (let popover of popovers) {
        const bounds = popover.getBoundingClientRect()
        if (bounds.height + bounds.top > window.innerHeight) {
          (popover as HTMLElement).style.top = ((popover as HTMLElement).offsetTop + window.innerHeight - bounds.height - bounds.top) + 'px'
        }
      }
    } else if (t < 10) {
      setTimeout(() => {
        this.movePopoverIntoWindow(t + 1)
      }, 1 + t)
    }
  }

  get isOnMobile(){
    return breakpointUtil.isOnMobile()
  }

  createCalendar(){
    if (this.newCalendarName === "") return
    const newOne: Calendar = new Calendar()
    newOne.name = this.newCalendarName
    this.newCalendarLoading = true
    calendarServiceApi._createCalendar(newOne).then(() => {
      this.toast.success(this.i18n.$gettext("Calendar created"))
      this.newCalendarName = ""
      this.showNewCalendarModal = false
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not create calendar"))
    }).finally(() => {
      this.newCalendarLoading = false
    })
  }

  @Watch('selectedCalendars')
  watchSelectedCalendars() {
    if (this.monthButton) {
      this.saveSelectedCalendars()
    }
  }

  saveSelectedCalendars() {
    const calendars = this.selectedCalendars.filter(c => c.length > 0).join(',')
    void SettingsUtil.setLastViewedCollection('calendar', calendars)
  }

  @Watch('route.params')
  watchRouteParams(params: any) {
    if (this.monthButton && params.hasOwnProperty("calendar") && this.selectedCalendars.indexOf(params["calendar"] as string) < 0) {
      this.selectedCalendars.push(params["calendar"] as string)
      this.saveSelectedCalendars()
    }
  }

  get hasCalendarsBooked(): boolean {
    return featureSubset.hasDAV
  }

  mounted() {
    let lastViewed: string | null | undefined = SettingsUtil.getLastViewedCollection('calendar')
    if (lastViewed) {
      this.selectedCalendars = []
      const selectedCalendars = lastViewed.split(',').map(id => this.calendars?.find(c => c.originalId === id || c.id === id)?.originalId)
      for (let originalId of selectedCalendars) {
        if (originalId) this.selectedCalendars.push(originalId)
      }
    }
    if (this.calendarId && !this.selectedCalendars.includes(this.calendarId)) {
      this.selectedCalendars.push(this.calendarId)
    }
    if (this.selectedCalendars.length === 0 && this.calendars.length > 0) {
      //TODO Find default calendar instead of first one
      const calendarId = this.calendars[0].originalId
      if (calendarId) {
        this.selectedCalendars.push(calendarId)
      }
    }
    if (!this.calendarId && this.selectedCalendars.length > 0) {
      this.goToCalendarId(this.selectedCalendars[0])
    }
    this.initCalendarHeaderButtons()
  }
}
